// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const firebaseConfig = {
  apiKey: "AIzaSyBJ1-e7d0U8AlSE2WF7dpqIuAoCV8D18pU",
  authDomain: "app-ok-wiki-50dbf.firebaseapp.com",
  databaseURL: "https://app-ok-wiki-50dbf.firebaseio.com",
  projectId: "app-ok-wiki-50dbf",
  storageBucket: "app-ok-wiki-50dbf.appspot.com",
  messagingSenderId: "1057137987148",
  appId: "1:1057137987148:web:b9e9cd9374969e30cceee9",
  measurementId: "G-2HZPFNJRF2"
};

const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const environment = {
  production: true,
  froala: {
    key: 'gVG3C-8F2G2B2C1C1B1ueh1F4zgdymD6F5F4H4E1B9B5C3C4B4=='
  },
  firebase: firebaseConfig,
  regexPattern: {
    email: emailPattern
  },
  role: {
    admin: 'admin',
    writer: 'writer',
    reader: 'reader',
    guest: 'guest'
  },
  orgRole: {
    admin: 'admin',
    projectSpecific: 'projectSpecific'
  },
  headerAllowedUrls: ['explore', 'users', 'pages'],
  api: 'https://app.ok.wiki'
  // api: 'http://localhost:5001/app-ok-wiki-50dbf/us-central1/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
