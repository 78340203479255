export const customerInterviewReport1Content = `<h1>Customer interview 1</h1>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 17.4893%; background-color: rgb(239, 239, 239);">Date of interview</td>
            <td style="width: 82.4034%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 17.4893%; background-color: rgb(239, 239, 239);">Interviewers</td>
            <td style="width: 82.4034%;">@mention interviewer</td>
        </tr>
        <tr>
            <td style="width: 17.4893%; background-color: rgb(239, 239, 239);">Customer name</td>
            <td style="width: 82.4034%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 17.4893%; background-color: rgb(239, 239, 239);">Customer contact info</td>
            <td style="width: 82.4034%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 17.4893%; background-color: rgb(239, 239, 239);">Company name</td>
            <td style="width: 82.4034%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Top takeaways</h2>

<ol>
    <li>List your top 2-3 takeaways from the interview</li>
    <li>...</li>
</ol>

<p>
    <br>
</p>

<h2>Background</h2>

<p>Add details about the customer, including their industry, role, and experience with your products</p>

<p>Put interview questions and answers here for reference. To change the title of the expand, tap the pencil icon.</p>

<ul>
    <li>Q1: Add your question

        <ul>
            <li>A1: Add the customer's answer</li>
        </ul>
    </li>
    <li>...</li>
</ul>

<h2>Use cases</h2>

<p>List the customer's use cases for your product or service</p>

<ul>
    <li>case 1</li>
    <li>...</li>
</ul>

<h2>Observations</h2>

<p>Highlight problems, takeaways, and opportunities you observed during the interview</p>

<h3>Problem:</h3>

<ul>
    <li>Takeaway:</li>
    <li>Opportunities:</li>
    <li>Tickets:&nbsp;</li>
</ul>

<h3>Problem:</h3>

<ul>
    <li>Takeaway:</li>
    <li>Opportunities:</li>
    <li>Tickets:</li>
</ul>`