export const collective = `<h1>Collective intelligence</h1>

<p><a href="https://en.wikipedia.org/wiki/Collective_intelligence">https://en.wikipedia.org/wiki/Collective_intelligence</a></p>

<p>Collective intelligence&nbsp;(CI) is shared or group intelligence that&nbsp;emerges&nbsp;from the&nbsp;collaboration, collective efforts, and competition of many individuals and appears in&nbsp;consensus decision making. The term appears in&nbsp;sociobiology,&nbsp;political science&nbsp;and in context of mass&nbsp;peer review&nbsp;and&nbsp;crowdsourcing&nbsp;applications. It may involve&nbsp;consensus,&nbsp;social capital&nbsp;and formalisms such as&nbsp;voting systems,&nbsp;social media&nbsp;and other means of quantifying mass activity. Collective IQ is a measure of collective intelligence, although it is often used interchangeably with the term collective intelligence. Collective intelligence has also been attributed to&nbsp;bacteria&nbsp;and animals.</p>

<p><br><img src="https://upload.wikimedia.org/wikipedia/commons/3/3e/CI_types1s_2.jpg" style="width: 600px;" class="fr-fic fr-dib"></p>

<p>It can be understood as an emergent property from the synergies among: 1) data-information-knowledge; 2) software-hardware; and 3) experts (those with new insights as well as recognized authorities) that continually learns from feedback to produce just-in-time knowledge for better decisions than these three elements acting alone;[2] or more narrowly as an emergent property between people and ways of processing information.[3] This notion of collective intelligence is referred to as "symbiotic intelligence" by Norman Lee Johnson.[4] The concept is used in sociology, business, computer science and mass communications: it also appears in science fiction. Pierre Lévy defines collective intelligence as, "It is a form of universally distributed intelligence, constantly enhanced, coordinated in real time, and resulting in the effective mobilization of skills. I'll add the following indispensable characteristic to this definition: The basis and goal of collective intelligence is mutual recognition and enrichment of individuals rather than the cult of fetishized or hypostatized communities."[5] According to researchers Pierre Lévy and Derrick de Kerckhove, it refers to capacity of networked ICTs (Information communication technologies) to enhance the collective pool of social knowledge by simultaneously expanding the extent of human interactions.</p>

<p>Collective intelligence is used to help create widely known platforms including Google, Wikipedia and political groups. Google is a major search engine that is made of millions of websites that have been created by people all around the world. It has the ability to share knowledge and creativity with each other to collaborate and expand thoughts and expressions. Google includes five key dynamics within their teams to create a well-collaborated system. Dynamics include psychological safety, dependability, structure &amp; clarity, meaning of work and impact of work.[12] Their ideas behind their rediscovery of collective intelligence is to ensure that all workers can express themselves without any fear of potential embarrassment.[13] Google's teamwork is said to be one of the main reasons for their success by including the use of emotional and collective intelligence to ensure teamwork is involved in any discussions.[14] The system behind Google exemplifies the combining of knowledge of the web-to-people not just knowledge of people-to-people.[15]</p>
`