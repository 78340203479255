import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Comment } from '../models/comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(
    private http: HttpClient
  ) { }

  public add(comment: {
    content: string,
    pageId: string,
    parent: string,
    projectId: string,
    orgId: string
  }): Observable<{
    data: Comment
  }> {
    return this.http.post<any>(`${environment.api}/api/v1/orgs/${ comment.orgId }/projects/${ comment.projectId }/pages/${ comment.pageId }/comments`, {
      content: comment.content,
      parent: comment.parent
    });
  }

  public get(model: {
    pageId: string,
    projectId: string,
    orgId: string
  }): Observable<{
    data: Array<Comment>
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${ model.orgId }/projects/${ model.projectId }/pages/${ model.pageId }/comments`);
  }

  public edit(comment: {
    id: string,
    content: string,
    pageId: string,
    projectId: string,
    orgId: string
  }): Observable<{
    data: Comment
  }> {
    return this.http.patch<any>(`${environment.api}/api/v1/orgs/${ comment.orgId }/projects/${ comment.projectId }/pages/${ comment.pageId }/comments/${ comment.id }`, {
      content: comment.content
    });
  }

  public delete(comment: {
    id: string,
    pageId: string,
    projectId: string,
    orgId: string
  }): Observable<any> {
    return this.http.delete<any>(`${environment.api}/api/v1/orgs/${ comment.orgId }/projects/${ comment.projectId }/pages/${ comment.pageId }/comments/${ comment.id }`);
  }

}
