export const decisionsContent = `
<h1>Decisions</h1>

<p>Raise your hand if you’ve been stuck in an endless loop of options exploration with your team, with a final decision seeming like an unachievable dream. Break the cycle by using the decision template. Prevent rehashing and instead record each direction
    your team has considered and the pros and cons for any future reference. You’ll also avoid analysis paralysis by putting someone in charge of making sure the decision actually gets made and assigning a single approver who ultimately makes the call.
    The template also offers a space to report on decision status, so all the key players can know which milestone you’re about to reach and work from the same foundation.</p>

<h2>How to use the decision template</h2>

<h3>Step 1. Just the facts</h3>

<p>You’ll see that the template starts off with a chart where you can fill in high-level information like decision status, the impact, the involved parties, the due date, and the outcome. These items may seem like no brainers, but take some time to review
    all these items with your stakeholders, especially those you name as drivers, approvers, and contributors. They should be well aware of their level of responsibility and their role in decision making for the project or you will definitely find yourself
    looking back at blank stares. You’ll want to check with these same people about the impact of the project as well so that you’re all on the same page in terms of priority and investment of resources.</p>

<h3>Step 2. Give context</h3>

<p>For the Background section, offer a 1–2 sentence explanation of what the considerations are and what decision needs to be made. Briefly speak to the importance of the decision and what it will impact.</p>

<p>With Ok.wiki, you can embed charts and graphs that illustrate relevant data points that might influence the decision you and the team make. Go ahead and showcase your Google Sheet or Excel doc right in the page to keep all the factors of your decision
    in one place.</p>

<h3>Step 3. Jot down your options</h3>

<p>All the elements of the template are important, but the Options considered section is where you really get down to the heart of the matter. Come to this section once you have sorted out at least three possibilities for your project. Perhaps you had a
    brainstorm, took notes, and have arrived at this template for your final documentation. Perhaps you had a virtual discussion in another wiki page or Slack. Or, you took the lead and came up with a few options on your own to present to the larger group.
    No matter how you reached this point, here’s where you capture all those ideas.</p>

<p>Think about the pros and cons of each one and jot that down too. When you estimate the cost, consider the monetary as well as the resources (team members, time, external vendors, materials, etc.) each one will need. The template has simple color-coding
    for high, medium, and low cost, but if you need more categories, hey: it’s a template! Customize it as you see fit.</p>

<h3>Step 4: Action items</h3>

<p>What else do you need to move forward? In the Action items section, detail what more you and the team need to know, do, or answer before you can call this part of the project a wrap. Don’t forget to @mention the names of those on the team who will be
    in charge of each action item.</p>

<h3>Step 5. Outcome</h3>

<p>Wiki pages are alive. That means that you can edit as many times as you need to as your project evolves. In the last section, add in what your final decision was. You may need to address everything in the action items area first and come back to the outcome
    later. Or, if you have a live meeting to discuss this decision, you might determine the outcome right then and there. Either way, put it down to save it for anyone in your company who may want to know how the team arrived at your conclusions.</p>

<p>
    <br>
</p>
`