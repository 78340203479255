import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { MyLocalStorageService, StateService, UserService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private localStorageService: MyLocalStorageService,
    private stateService: StateService
  ) {
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any 
  {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await this.stateService.getAuthUser();
        if (user) {
          console.log('auth.guard: user logged in', user.uid);
          resolve(true);
        } else {
          reject('No user logged in');
          this.router.navigateByUrl('/account/signin');
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  
}
