export const productRequirements1Content = `<h1>Product requirements</h1>

<p>Fill in project details in the table below. Type /date to quickly add the target release date, /jira to link to Jira epics or issues, and @mention functional owners to keep everyone on the same page.</p>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 15.7042%; background-color: rgb(239, 239, 239);">Target release</td>
            <td style="width: 84.1945%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 15.7042%; background-color: rgb(239, 239, 239);">Document owner</td>
            <td style="width: 84.1945%;">@mention someone</td>
        </tr>
        <tr>
            <td style="width: 15.7042%; background-color: rgb(239, 239, 239);">Designer</td>
            <td style="width: 84.1945%;">@mention someone</td>
        </tr>
        <tr>
            <td style="width: 15.7042%; background-color: rgb(239, 239, 239);">Tech lead</td>
            <td style="width: 84.1945%;">@mention someone</td>
        </tr>
        <tr>
            <td style="width: 15.7042%; background-color: rgb(239, 239, 239);">Technical writer</td>
            <td style="width: 84.1945%;">@mention someone</td>
        </tr>
        <tr>
            <td style="width: 15.7042%; background-color: rgb(239, 239, 239);">QA</td>
            <td style="width: 84.1945%;">@mention someone</td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Objective</h2>

<p>Provide context on this project and explain how it fits into your organization's strategic goals</p>

<h2>Success metrics</h2>

<p>List project goals and the metrics you'll use to judge its success</p>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Goal</td>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Metric</td>
        </tr>
        <tr>
            <td style="width: 50.0000%;">e.g., Simplify the user experience</td>
            <td style="width: 50.0000%;">e.g., Customer satisfaction score increases</td>
        </tr>
        <tr>
            <td style="width: 50.0000%;">...</td>
            <td style="width: 50.0000%;">...</td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Assumptions</h2>

<p>List any assumptions you have about your users, technical constraints, or business goals (e.g., Most users will access this feature from a tablet)</p>

<ul>
    <li>...</li>
    <li>...</li>
</ul>

<h2>Milestones</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 34.1439%; background-color: rgb(239, 239, 239);">Feature</td>
            <td style="width: 13.0699%; background-color: rgb(239, 239, 239);">Date</td>
            <td style="width: 27.5583%; background-color: rgb(239, 239, 239);">Owner</td>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Status</td>
        </tr>
        <tr>
            <td style="width: 34.1439%;">
                <br>
            </td>
            <td style="width: 13.0699%;">
                <br>
            </td>
            <td style="width: 27.5583%;">@mention someone</td>
            <td style="width: 25.0000%;">Go/No Go</td>
        </tr>
        <tr>
            <td style="width: 34.1439%;">
                <br>
            </td>
            <td style="width: 13.0699%;">
                <br>
            </td>
            <td style="width: 27.5583%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Requirements</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 25.5319%;">Requirement</td>
            <td style="width: 34.4478%;">User Story</td>
            <td style="width: 14.9949%;">Importance</td>
            <td style="width: 24.924%;">Notes</td>
        </tr>
        <tr>
            <td style="width: 25.5319%;">e.g., Must be mobile responsive</td>
            <td style="width: 34.4478%;">e.g., John is a PM who wants to check on his team's progress from the train station</td>
            <td style="width: 14.9949%;">HIGH / MIDIUM / LOW</td>
            <td style="width: 24.924%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.5319%;">
                <br>
            </td>
            <td style="width: 34.4478%;">
                <br>
            </td>
            <td style="width: 14.9949%;">
                <br>
            </td>
            <td style="width: 24.924%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.5319%;">
                <br>
            </td>
            <td style="width: 34.4478%;">
                <br>
            </td>
            <td style="width: 14.9949%;">
                <br>
            </td>
            <td style="width: 24.924%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>User interaction and design</h2>

<p>Add mockups, diagrams, or visual designs related to these requirements. Type /image to upload a file.</p>

<h2>Open Questions</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 33.2996%;">Question</td>
            <td style="width: 52.077%;">Answer</td>
            <td style="width: 14.5897%;">Date Answered</td>
        </tr>
        <tr>
            <td style="width: 33.2996%;">e.g., How might we make users more aware of this feature?</td>
            <td style="width: 52.077%;">e.g., We'll announce the feature with a blog post and a Summit presentation</td>
            <td style="width: 14.5897%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 33.2996%;">
                <br>
            </td>
            <td style="width: 52.077%;">
                <br>
            </td>
            <td style="width: 14.5897%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Out of Scope</h2>

<p>List the features discussed which are out of scope or might be revisited in a later release.</p>

<ul>
    <li>...</li>
    <li>...</li>
</ul>`