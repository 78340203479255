export const goals1Content = `<h1>Goals, signals, measures</h1>

<p>North Star: Summarize the larger team-, department-, or company-wide objective your project contributes to</p>

<p><strong>Time horizon:</strong> Use /date to quickly add the date range your goals will cover</p>

<h2>Goals</h2>

<p>Separate the goals you are pursuing and those that you are not pursuing in the table below. Remember, goals should be outcomes, not outputs of effort</p>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Pursuing</td>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Not pursuing</td>
        </tr>
        <tr>
            <td style="width: 50.0000%;">

                <ul>
                    <li>e.g., Drive awareness of our company values with external audience</li>
                    <li>Goal #2</li>
                    <li>Goal #3</li>
                </ul>
            </td>
            <td style="width: 50.0000%;">

                <ul>
                    <li>...</li>
                    <li>...</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td style="width: 50.0000%;">
                <br>
            </td>
            <td style="width: 50.0000%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Signals &amp; Measures</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">
                <br>
            </td>
            <td style="width: 37.3812%; background-color: rgb(239, 239, 239);">
                <div style="text-align: center;">e.g., Drive awareness of our company values with external audience</div>
            </td>
            <td style="width: 17.2122%; background-color: rgb(239, 239, 239);">
                <div style="text-align: center;">Goal #2</div>
            </td>
            <td style="width: 20.2746%; background-color: rgb(239, 239, 239);">
                <div style="text-align: center;">Goal #3</div>
            </td>
        </tr>
        <tr>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Signals</td>
            <td style="width: 37.3812%;">

                <ul>
                    <li>e.g., Page views of our website</li>
                </ul>
            </td>
            <td style="width: 17.2122%;">
                <br>
            </td>
            <td style="width: 20.2746%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Measures</td>
            <td style="width: 37.3812%;">

                <ul>
                    <li>e.g., 10% monthly growth in page views of &nbsp;our company website</li>
                </ul>
            </td>
            <td style="width: 17.2122%;">
                <br>
            </td>
            <td style="width: 20.2746%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Why we chose that target</td>
            <td style="width: 37.3812%;">

                <ul>
                    <li>Traffic to our company website helps us achieve our ultimate goal of recruiting top talent</li>
                </ul>
            </td>
            <td style="width: 17.2122%;">
                <br>
            </td>
            <td style="width: 20.2746%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>`