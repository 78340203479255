import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './resolvers';
import { NotFoundComponent } from './components';
import { PageViewComponent } from "./pages/explore/page-view/page-view.component";
import { UserComponent } from "./pages/account/user/user.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    redirectTo: 'orgs'
  },
  { 
    path: 'orgs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/organizations/organizations.module').then(m => m.OrganizationsModule)
  },
  { 
    path: 'explore',
    loadChildren: () => import('./pages/explore/explore.module').then(m => m.ExploreModule)
  },
  {
    path: 'pages/:pageId',
    component: PageViewComponent
  },
  { 
    path: 'feed',
    loadChildren: () => import('./pages/feed-list/feed-list.module').then(m => m.FeedModule)
  },
  { 
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
  },
  { 
    path: 'account', 
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) 
  },
  {
    path: 'users/:userId',
    component: UserComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
