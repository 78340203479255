import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from "ng-zorro-antd"
import { StateService, MyLocalStorageService, UserService, ValidateService } from './services';
import { environment } from '../environments/environment';

@Component({
  selector: 'ok-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  profileForm: FormGroup;
  newPasswordForm: FormGroup;

  isModalProfileVisible = false;
  isSavingProfile = false;

  isModalNewPasswordVisible = false;

  constructor(
    private router: Router,
    public state: StateService,
    public localhostService: MyLocalStorageService,
    private userService: UserService,
    private messageService: NzMessageService,
    private validateService: ValidateService,
    private fb: FormBuilder
  ) {
    this.profileForm = this.fb.group({
      email: [null, [Validators.email, Validators.required, Validators.maxLength(40)]],
      nickname: [null, [Validators.required, Validators.maxLength(40)]],
      company: [null],
      firstName: [null],
      lastName: [null],
      website: [null]
    });

    this.newPasswordForm = this.fb.group({
      newPassword: [null, [Validators.required]],
      newPasswordConfirm: [null]
    }, {
      validator: this.validateService.comparePassword("newPassword", "newPasswordConfirm")
    });
  }

  get canShowHeader(): boolean {
    const url = this.router.url;
    return this.state.userDataLoaded || 
      environment.headerAllowedUrls.some(allowed => url.includes(allowed));
  }

  get isProfileChanged() {
    const {
      email,
      nickname,
      company,
      firstName,
      lastName,
      website
    } = this.profileForm.value;

    if (email !== this.state.userEmail
      || nickname !== this.state.nickname
      || company !== this.state.userCompany
      || firstName !== this.state.userFirstName
      || lastName !== this.state.userLastName
      || website !== this.state.userWebsite) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {}

  async modalProfileOpen() {
    this.profileForm.setValue({
      email: this.state.userEmail,
      nickname: this.state.nickname,
      company: this.state.userCompany,
      firstName: this.state.userFirstName,
      lastName: this.state.userLastName,
      website: this.state.userWebsite
    });
    this.isModalProfileVisible = true;
  }

  modalProfileCancel() {
    this.isModalProfileVisible = false;
  }

  modalNewPasswordOpen() {
    this.isModalNewPasswordVisible = true;
  }

  modalNewPasswordCancel() {
    this.isModalNewPasswordVisible = false;
  }

  updateProfile() {
    if (this.isProfileChanged) {
      const {
        email,
        nickname,
        company,
        firstName,
        lastName,
        website
      } = this.profileForm.value;

      this.isSavingProfile = true;

      this.userService.updateUserInfo({
        id: this.state.userId,
        email,
        nickname,
        company,
        firstName,
        lastName,
        website
      }).subscribe(
        async (res) => {
          console.log("user settings updated", res);
          const user = res.data;
          this.isSavingProfile = false;
          this.state.notifyAll({ mode: "state", value: "updateUser", user });
          this.messageService.success("Profile is updated");
        },
        (err) => {
          console.log(err);
          this.isSavingProfile = false;

          if (err.status === 400) {
            const { error } = err.error;
            this.messageService.error("Error! " + error.message, {
              nzDuration: 6000
            });
          } else {
            this.messageService.error("Something went wrong! Error!");
          }
        }
      );
    }
  }

  setNewPassword() {
    if (this.newPasswordForm.valid) {
      const { newPassword } = this.newPasswordForm.value;
      
      this.isSavingProfile = true;

      this.userService.updateUserInfo({
        id: this.state.userId,
        password: newPassword
      }).subscribe(
        async (res) => {
          await this.state.signIn(this.state.userEmail, newPassword);
          
          this.isSavingProfile = false;
          this.messageService.success("Password is changed");
          this.newPasswordForm.reset();
          this.modalNewPasswordCancel();
        },
        (err) => {
          console.log(err);
          this.isSavingProfile = false;
          this.messageService.error("Something went wrong! Error: " + err.message);
          this.newPasswordForm.reset();
        }
      )
    }
  }
}
