import { Injectable } from '@angular/core';
import { NzTreeNodeOptions } from "ng-zorro-antd/tree";

import { organizations, projects, pages, permissions } from "../mockdata/data";
import { Organization, Project, ProjectUpdateBody, Page, PagesOrder, Permission, UserRole, User } from "../models";
import { StateService } from '../services/state.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

interface OrganizationTreeNode extends NzTreeNodeOptions, Organization {}
interface ProjectTreeNode extends NzTreeNodeOptions, Project {}

function traversePage(page: PagesOrder): {} {
  return {
    key: page.id,
    title: page.name,
    children: page.pagesOrder && page.pagesOrder.length ? page.pagesOrder.map(p => traversePage(p)) : [],
    isLeaf: !page.pagesOrder,
    expanded: page.pagesOrder && page.pagesOrder.length
  }
}

@Injectable()
export class ProjectService {

  private _permissions = permissions;
  private _organizations = organizations
  private _projects = projects;
  private _pages = pages;

  orgTree: { [key: string]: NzTreeNodeOptions } = {};

  orgTreeById = this.reduceOrgsToMap();

  projTreeById = this.reduceProjectsToMap();

  get permissions(): Permission[] {
    return this._permissions;
  }

  set permissions(value: Permission[]) {
    this._permissions = value;
  }

  get organizations(): Organization[] {
    return this._organizations;
  }

  set organizations(value: Organization[]) {
    this._organizations = value;
    this.orgTreeById = this.reduceOrgsToMap();
  }

  get projects(): Project[] {
    return this._projects;
  }

  set projects(value: Project[]) {
    this._projects = value;
    this.projTreeById = this.reduceProjectsToMap();
  }

  get pages(): Page[] {
    return this._pages;
  }

  set pages(value: Page[]) {
    this._pages = value;
  }

  constructor(
    private state: StateService,
    private http: HttpClient
  ) {

  }

  getPageContent(pageId: string): string {
    return "";
  }

  getOrganizationById(orgId: string) {
    return this.orgTreeById[orgId];
  }

  getProjectById(projId: string) {
    return this.projTreeById[projId];
  }

  getPageById(pageId: string): Page {
    return this.pages.filter(page => {
      if (page && page.id) {
        return page.id === pageId
      } else {
        return false;
      }
    })[0];
  }

  hasProjectOrgTree(orgId: string, projId: string): boolean {
    const proj = this.orgTree[orgId].children!.filter(project => project.key === projId);
    return proj.length > 0;
  }

  addOrganization(name: string): { [key: string]: NzTreeNodeOptions } {
    const id = `org_${this.organizations.length}`;
    const org: Organization = {
      id,
      name,
      createdBy: this.state.nickname, createdDate: new Date(), updatedBy: this.state.nickname, updatedDate: new Date()
    };
    this.organizations = [...this.organizations, org];
    this.orgTree[id] = {
      key: id,
      title: name,
      expanded: true,
      icon: "home",
      children: []
    };

    this.createOrganizationPermission(org, "admin");

    return this.orgTree;
  }

  public addProject(project: Project): NzTreeNodeOptions {
    return {
      key: project.id,
      title: project.name,
      expanded: true,
      icon: "project",
      children: []
    };
  }

  public setUnselected(nodeTree: Array<NzTreeNodeOptions>): void {
    console.log('setUnselected');
    for (const node of nodeTree) {
      node.selected = false;
      if (!node.children || node.children.length < 1) {
        continue;
      }
      this.setUnselected(node.children);
    }
  }

  public transformToTree(permissions: Array<Permission>): Array<NzTreeNodeOptions> {
    let projectTree = new Array<NzTreeNodeOptions>();

    permissions.forEach((permission) => {
      projectTree.push({
        key: permission.project.id,
        title: permission.project.name,
        expanded: true,
        icon: "project",
        children: []
      });
    });
    return projectTree;
  }

  addNestedPage(name: string, pageId: string): NzTreeNodeOptions[] {
    const parentPage = this.getPageById(pageId);
    const newPageId = `page_${this.pages.length}`;
    const newPage: Page = {
      id: newPageId,
      name,
      projectId: parentPage.projectId,
      organizationId: parentPage.organizationId,
      content: "",
      createdBy: this.state.nickname, createdDate: new Date(), updatedBy: this.state.nickname, updatedDate: new Date()
    };

    this.pages = [...this.pages, newPage];

    const project = this.projTreeById[parentPage.projectId];

    const pagesOrder: PagesOrder[] = project.pagesOrder || [];

    const page = pagesOrder
      .map(aPage => this.traversePagesOrder(aPage, pageId))
      .filter(aPage => aPage !== null)[0];

    if (page && page.pagesOrder) {
      page.pagesOrder.push({ id: newPageId, name });
    } else {
      page && (page.pagesOrder = [{ id: newPageId, name }])
    }

    project.pagesOrder = pagesOrder;

    return pagesOrder.map((page: PagesOrder) => traversePage(page)) as NzTreeNodeOptions[];
  }

  createOrganizationPermission(org: Organization, role: UserRole): Array<Permission> {
    const permission: Permission = {
      id: `perm_${this.permissions.length}`,
      userId: this.state.userId || "usr_0",
      user: {
        nickname: this.state.nickname
      },
      organization: {
        name: org.name
      },
      role,
      createdBy: this.state.nickname, createdDate: new Date(), updatedBy: this.state.nickname, updatedDate: new Date()
    };
    this.permissions = [...this.permissions, permission];

    return permissions;
  }

  createProjectPermission(project: Project, role: UserRole): Permission {
    const permission: Permission = {
      id: `perm_${this.permissions.length}`,
      userId: this.state.userId || "usr_0",
      user: {
        nickname: this.state.nickname
      },
      project: {
        id: project.id,
        name: project.name,
        organizationId: project.organizationId
      },
      role,
      createdBy: this.state.nickname, createdDate: new Date(), updatedBy: this.state.nickname, updatedDate: new Date()
    };
    this.permissions = [...this.permissions, permission];

    return permission;
  }

  reduceOrgsToMap() {
    return this._organizations.reduce((acc, organization) => {
      acc[organization.id] = { ...organization, key: organization.id, title: organization.name, children: [], icon: 'home' }
      return acc
    }, {} as { [key: string]: OrganizationTreeNode });
  }

  reduceProjectsToMap() {
    return this._projects.reduce((acc, project) => {
      acc[project.id] = { ...project, key: project.id, title: project.name, children: [], isLeaf: true, icon: 'project' }
      return acc
    }, {} as { [key: string]: ProjectTreeNode });
  }

  traversePagesOrder(pagesOrder: PagesOrder, pageId: string): PagesOrder | null {
    if (pagesOrder.id === pageId) {
      return pagesOrder;
    } else if (pagesOrder.pagesOrder && pagesOrder.pagesOrder.length) {
      return pagesOrder.pagesOrder.map(page => this.traversePagesOrder(page, pageId))[0];
    } else {
      return null
    }
  }

  getProjectPages(projectId: string) {
    return this.pages.filter(page => page ? page.projectId === projectId : false);
  }

  getPageList(projectId: string): NzTreeNodeOptions[] {
    const pages = this.getProjectPages(projectId);
    const sorted = pages.sort((page1, page2) => page1.updatedDate >= page2.updatedDate ? -1 : 1);
    const list: NzTreeNodeOptions[] = sorted.map(page => {
      return {
        key: page.id,
        title: page.name,
        isLeaf: true,
        updatedDate: page.updatedDate.toLocaleDateString(),
        updatedTime: page.updatedDate.toLocaleTimeString(),
        updatedBy: page.updatedBy
      }
    });
    return list;
  }

  public findPageKey(pagesTree: NzTreeNodeOptions[], pageKey: string): string {
    if (!pagesTree || pagesTree.length < 1 || !pageKey) {
      return;
    }

    let childrenTree = [];
    for (let i=0; i<pagesTree.length; i++) {
      if (pagesTree[i].key === pageKey) {
        return pageKey;
      }

      if (pagesTree[i].children && pagesTree[i].children.length > 0) {
        childrenTree.push(pagesTree[i].children);
      }
    }

    if (childrenTree.length < 1) {
      return pageKey;
    }

    return this.findPageKey(childrenTree, pageKey);
  }

  public add(project: Project, withSampleData: boolean = false): Observable<{
    data: {
      project: Project,
      permissions: Permissions
    }
  }> {
    const query = withSampleData ? "?withSampleData=true" : "";
    return this.http.post<any>(`${environment.api}/api/v1/orgs/${project.organizationId}/projects${query}`, project);
  }

  public edit(project: ProjectUpdateBody, ids: { orgId: string, projectId: string }): Observable<any> {
    return this.http.patch<any>(`${environment.api}/api/v1/orgs/${ids.orgId}/projects/${ids.projectId}`, project);
  }

  public getById(id: string, orgId: string): Observable<{
    data: Project
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${ orgId }/projects/${ id }`);
  }

  public delete(id: string, orgId: string): Observable<any> {
    return this.http.delete<any>(`${environment.api}/api/v1/orgs/${ orgId }/projects/${ id }`);
  }

  public leaveProject(orgId: string, projectId: string, permissionId: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.api}/api/v1/orgs/${orgId}/projects/${projectId}/permissions/${permissionId}`);
  }

  // public async deleteFromPagesOrder(model: {
  //   projectId: string,
  //   pageId: string,
  //   orgId: string
  // }) {
  //   const projectInfo = await this.getById(model.projectId, model.orgId).toPromise();
  //   if (!projectInfo || !projectInfo.data) {
  //     return;
  //   }

  //   const pagesOrder = this.findAndDeletePageOrder(projectInfo.data.pagesOrder || [], model.pageId);
  //   await this.edit({
  //     id: projectInfo.data.id,
  //     name: projectInfo.data.name,
  //     type: projectInfo.data.type,
  //     pagesOrder: pagesOrder.results
  //   }).toPromise();
  // }

  // public async updatePagesOrder(projectId: string, page: Page) {
  //   const projectInfo = await this.getById(projectId).toPromise();
  //   console.log('projectInfo', projectInfo);
  //   if (!projectInfo || !projectInfo.data) {
  //     return;
  //   }

  //   const pagesOrder = await this.addToPageOrder(projectInfo.data.pagesOrder || [], page);
  //   await this.edit({
  //     id: projectInfo.data.id,
  //     name: projectInfo.data.name,
  //     type: projectInfo.data.type,
  //     pagesOrder: pagesOrder
  //   }).toPromise();
  // }

  public addToPageOrder(pagesOrder: Array<PagesOrder>, page: Page): Array<PagesOrder> {
    if(page.parentId) {
      const parentPage = this.findPageOrder(pagesOrder, page.id);
      if (parentPage) {
        parentPage.pagesOrder.push({
          id: page.id,
          name: page.name
        });
      }

      return pagesOrder;
    }

    pagesOrder.push({
      id: page.id,
      name: page.name
    });

    return pagesOrder;
  }

  public findPageOrder(pagesOrder: Array<PagesOrder>, pageId: string): PagesOrder {

    for (let i=0; i < pagesOrder.length; i++) {
      if (pagesOrder[i].id === pageId) {
        return pagesOrder[i];
      }

      const findPage = this.findPageOrder(pagesOrder[i].pages || [], pageId);
      if (findPage) return findPage;
    }

    return;
  }

  public findAndDeletePageOrder(pagesOrder: Array<PagesOrder>, pageId: string): {
    isFind: boolean,
    results: Array<PagesOrder>
  } {
    for (let i=0; i < pagesOrder.length; i++) {
      if (pagesOrder[i].id === pageId) {
        return {
          isFind: true,
          results: pagesOrder.filter(x => x.id !== pageId)
        };
      }

      const result = this.findAndDeletePageOrder(pagesOrder[i].pagesOrder || [], pageId);
      if (result.isFind) {
        pagesOrder[i].pagesOrder = result.results;
        return {
          isFind: true,
          results: pagesOrder
        };
      }
    }

    return {
      isFind: false,
      results: pagesOrder
    };
  }

  public addMember(member: {
    projId: string,
    role: string,
    projectName: string,
    user: User,
    organizationId: string
  }): Observable<{
    data: Permission
  }> {
    return this.http.post<any>(`${environment.api}/api/v1/orgs/${ member.organizationId }/projects/${member.projId}/permissions`, member);
  }

  public deleteMember(member: {
    permissionId: string,
    projectId: string,
    orgId: string
  }): Observable<any> {
    return this.http.delete<any>(`${environment.api}/api/v1/orgs/${ member.orgId }/projects/${member.projectId}/permissions/${member.permissionId}`);
  }

  public updateRole(member: {
    projectId: string,
    permissionId: string,
    role: string,
    orgId: string
  }): Observable<any> {
    return this.http.patch<any>(`${environment.api}/api/v1/orgs/${ member.orgId }/projects/${member.projectId}/permissions/${member.permissionId}`, {
      role: member.role
    });
  }

  getUsers(orgId: string, projectId: string, text = "") {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${orgId}/projects/${projectId}/users?search=${text}`)
  }
}
