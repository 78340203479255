export const projectPlanTemplate1Content = `<h1>Project plan 1</h1>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 33.3333%; background-color: rgb(239, 239, 239);">Driver</td>
            <td style="width: 33.3333%; background-color: rgb(239, 239, 239);">Approver</td>
            <td style="width: 33.3333%; background-color: rgb(239, 239, 239);">Contributors</td>
        </tr>
        <tr>
            <td style="width: 33.3333%;">@mention someone</td>
            <td style="width: 33.3333%;">@mention someone</td>
            <td style="width: 33.3333%;">@mention someone</td>
        </tr>
        <tr>
            <td style="width: 33.3333%;">
                <br>
            </td>
            <td style="width: 33.3333%;">
                <br>
            </td>
            <td style="width: 33.3333%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 16.2107%;">Objective</td>
            <td style="width: 83.688%;">Summarize the objective in one sentence</td>
        </tr>
        <tr>
            <td style="width: 16.2107%;">Due date</td>
            <td style="width: 83.688%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 16.2107%;">Key outcomes</td>
            <td style="width: 83.688%;">List expected outcomes and success metrics</td>
        </tr>
        <tr>
            <td style="width: 16.2107%;">Status</td>
            <td style="width: 83.688%;">NOT STARTED / STARTED / FINISHED</td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Problem Statement</h2>

<p>Describe the problem and its impact. Include the hypothesis that's driving your work ("We think X will do Y, and we'll know weve succeeded if Z").</p>

<h2>Scope</h2>

<p>Must have:</p>

<ul>
    <li>

        <p>Add your project's core requirements</p>
    </li>
</ul>

<p>Nice to have:</p>

<ul>
    <li>

        <p>Add anything you want but don't strictly need</p>
    </li>
</ul>

<p>Not in scope:</p>

<ul>
    <li>

        <p>Add anything you don't want to include</p>
    </li>
</ul>

<h2>Timeline</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 34.1439%; background-color: rgb(239, 239, 239);">Feature</td>
            <td style="width: 13.0699%; background-color: rgb(239, 239, 239);">Date</td>
            <td style="width: 27.5583%; background-color: rgb(239, 239, 239);">Owner</td>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Status</td>
        </tr>
        <tr>
            <td style="width: 34.1439%;">
                <br>
            </td>
            <td style="width: 13.0699%;">
                <br>
            </td>
            <td style="width: 27.5583%;">@mention someone</td>
            <td style="width: 25.0000%;">Go/No Go</td>
        </tr>
        <tr>
            <td style="width: 34.1439%;">
                <br>
            </td>
            <td style="width: 13.0699%;">
                <br>
            </td>
            <td style="width: 27.5583%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Milestones and deadlines</h2>

<p>Plot out milestones and deadlines using the table below. Put each milestone on its own row, @mention milestone owners, and type /date to quickly add deadlines.</p>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 25.304%; background-color: rgb(239, 239, 239);">Milestone</td>
            <td style="width: 28.1155%; background-color: rgb(239, 239, 239);">Owner</td>
            <td style="width: 18.4397%; background-color: rgb(239, 239, 239);">Deadline</td>
            <td style="width: 28.1662%; background-color: rgb(239, 239, 239);">Status</td>
        </tr>
        <tr>
            <td style="width: 25.304%;">
                <br>
            </td>
            <td style="width: 28.1155%;">@mention someone</td>
            <td style="width: 18.4397%;">
                <br>
            </td>
            <td style="width: 28.1662%;">NOT STARTED / STARTED / FINISHED</td>
        </tr>
        <tr>
            <td style="width: 25.304%;">
                <br>
            </td>
            <td style="width: 28.1155%;">
                <br>
            </td>
            <td style="width: 18.4397%;">
                <br>
            </td>
            <td style="width: 28.1662%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<h2>
    <br>
</h2>

<h2>Reference materials</h2>

<p>Add links to relevant research and any other key documents</p>

<ul>
    <li>

        <p>...</p>
    </li>
    <li>

        <p>...</p>
    </li>
</ul>`