import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Permission } from '../models';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http: HttpClient
  ) { }

  public getPermissions(uid: string): Observable<{
    data: Array<Permission>
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/user/${uid}/permissions`);
  }

  public getPermissionsForOrg(orgId: string): Observable<{
    data: Array<Permission>
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${orgId}/permissions`);
  }

  public getPermissionsForProj(projId: string, orgId: string): Observable<{
    data: Array<Permission>
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${ orgId }/projects/${projId}/permissions`);
  }
}
