export const projectPlanTemplateContent = `<h1>Project plan template</h1>

<p>Moving projects across the finish line is rewarding, but it’s far from easy. When you have multiple teams involved on a single project, it’s even tougher. Are you breaking out into a sweat just thinking about it? Here’s the good news: no matter what team
    you're on, the project plan template makes it easier to manage projects of any size and complexity, all while keeping stakeholders in the loop along the way.</p>

<h2>How to use the project plan template</h2>

<h3>Step 1. Enter your project players</h3>

<p>Clarifying roles and responsibilities is a crucial part of the project planning process. In this first section, you’ll list your driver (the project’s point person who will corral all stakeholders and keep things moving), your approver (the person who
    signs off on the final project), your contributors (people who have expertise and a voice in the project, but no final vote), and your stakeholders (people who just need to be informed of the project’s progress, but aren’t actively involved in getting
    the work done). Detailing these roles from the outset is more than a formality – it’ll ensure that everybody knows exactly where they stand as the project moves forward.</p>

<h3>Step 2. Fill out the nuts and bolts</h3>

<p>In this section, you’ll state the project’s objective in a single sentence and name your project. We recommend something straightforward, rather than getting too clever. That gives your whole team the clarity they need, without jargon and vague language.
    You’ll also jot down the due date for the entire project, any key outcomes and success metrics you’ll be monitoring, and the project’s status, which will be updated as your project moves forward. Having all of this nuts-and-bolts information at the
    top gives your whole team an easy place to get all of the need-to-know details, without digging through endless emails to find them.</p>

<h3>3. Map out your project scope</h3>

<p>Nothing will sabotage a project faster than scope creep, so you’ll need to get clear on what your project entails – as well as what it doesn’t. Using this template, you’ll list your project elements in three categories: must-have, nice to have, and not
    in scope. Outlining these at the start of the project will empower your team members to better manage their own pieces and focus on the work that matters most.</p>

<h3>4. Plan your milestones and deadlines</h3>

<p>Your project won’t stretch out into eternity (at least it shouldn’t). You’ll have an end date when the entire project should be wrapped up, but you’ll also want to set smaller milestones to help monitor progress and make the entire project more manageable.
    In this section, outline your different milestones (for example, a first content draft and design mockups) and include the primary owner of each requirement, the deadline, the status, and any important notes.</p>

<h3>5. Include reference materials</h3>

<p>You want your project plan to be the one-stop-shop for all of the important information related to that project. In the bottom section, include links to any other reference material that people might need. Whether it’s a creative brief or an example you
    want people to refer back to, collect that all here so it’s organized and accessible.</p>`