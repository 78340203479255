function randomNum(max, min = 0) {
  return Math.ceil(Math.random()*max) + min;
}

const pageMock = {
  id: "page_01",
  userImg: "https://www.thispersondoesnotexist.com/image",
  title: "Complete Python Bootcamp: Go from zero to hero in Python 3",
  userName: "John Marcus Doe",
  userId: "uid_01",
  followers: 200,
  copies: 20,
  likes: 136,
  dislikes: 5
};

export type PageDataType = typeof pageMock;

export const newDocs: PageDataType[] = [{
  id: "page_0",
  userImg: "assets/images/pages/CERN_1280p.jpg",
  title: "European physicists boldly take small step toward 100-kilometer-long atom smasher",
  userName: "Adrian Cho",
  userId: "uid_01",
  followers: randomNum(100),
  copies: randomNum(50),
  likes: randomNum(500),
  dislikes: randomNum(10),
}, {
    id: "page_1",
    userImg: "assets/images/pages/shutterstock_666690367.jpg",
    title: "Honey as a Wound Treatment? Scientists Are Exploring Its Potential Healing Effects",
    userName: "Allison Whitten",
    userId: "uid_02",
    followers: randomNum(100),
    copies: randomNum(50),
    likes: randomNum(500),
    dislikes: randomNum(10),
  }, {
    id: "page_2",
    userImg: "assets/images/pages/ca_0624_Assination_of_Ciceron.jpg",
    title: "Alaskan megaeruption may have helped end the Roman Republic",
    userName: "Paul Voosen",
    userId: "uid_03",
    followers: randomNum(100),
    copies: randomNum(50),
    likes: randomNum(500),
    dislikes: randomNum(10),
  }, {
    id: "page_3",
    userImg: "assets/images/pages/-3000_Abydos_Spiel_der_Schlange_Mehen_anagoria.jpeg",
    title: "The Ancient History of Board Games",
    userName: "Bridget Alex",
    userId: "uid_02",
    followers: randomNum(100),
    copies: randomNum(50),
    likes: randomNum(500),
    dislikes: randomNum(10),
  }, {
    id: "page_4",
    userImg: "assets/images/pages/eROSITA_1280p.jpg",
    title: "This is what our universe looks like to x-ray eyes",
    userName: "Daniel Clery",
    userId: "uid_01",
    followers: randomNum(100),
    copies: randomNum(50),
    likes: randomNum(500),
    dislikes: randomNum(10),
  }, {
    id: "page_",
    userImg: "assets/images/pages/shutterstock_365531318.jpg",
    title: "The AI Pop Star Learning to Sing Like a Human",
    userName: "The Physics arXiv Blog",
    userId: "uid_02",
    followers: randomNum(100),
    copies: randomNum(50),
    likes: randomNum(500),
    dislikes: randomNum(10),
}];
export const popularLiked: PageDataType[] = [{
  id: "page_5",
  userImg: "assets/images/pages/caveart_700p.jpg",
  title: "Mysterious ancient rock art may have been made with beeswax",
  userName: "Michael Price",
  userId: "uid_03",
  followers: randomNum(100),
  copies: randomNum(50),
  likes: randomNum(5000, 500),
  dislikes: randomNum(10),
}, {
    id: "page_6",
    userImg: "assets/images/pages/shutterstock_303790283.jpg",
    title: "What Happens in Your Brain When You Make Memories?",
    userName: "Alex Orlando",
    userId: "uid_01",
    followers: randomNum(100),
    copies: randomNum(50),
    likes: randomNum(5000, 500),
    dislikes: randomNum(10),
  }, {
    id: "page_7",
    userImg: "assets/images/pages/Monosmilus_1280p.jpg",
    title: "Saber-toothed anchovies roamed the oceans 45 million years ago",
    userName: "Rodrigo Pérez Ortega",
    userId: "uid_03",
    followers: randomNum(100),
    copies: randomNum(50),
    likes: randomNum(5000, 500),
    dislikes: randomNum(10),
}];
export const mostCopied: PageDataType[] = [{
  id: "page_8",
  userImg: "assets/images/pages/DSC-CR0720_02.png",
  title: "The Other Solar Power: How Scientists Are Making Fuel From Sunlight and Air",
  userName: "Frank Swain",
  userId: "uid_01",
  followers: randomNum(100),
  copies: randomNum(2500, 50),
  likes: randomNum(500),
  dislikes: randomNum(10),
}, {
    id: "page_9",
    userImg: "assets/images/pages/ca_0522NID_Researchers_Leaves_online.jpg",
    title: "Tropical forests soak up huge amounts of greenhouse gas. Climate change could end that",
    userName: "Elizabeth Pennisi",
    userId: "uid_02",
    followers: randomNum(100),
    copies: randomNum(2500, 50),
    likes: randomNum(500),
    dislikes: randomNum(10),
  }, {
    id: "page_10",
    userImg: "assets/images/pages/DSC-MS0720_100.png",
    title: "5 Exercise Myths Debunked by Science",
    userName: "Gemma Tarlach",
    userId: "uid_02",
    followers: randomNum(100),
    copies: randomNum(2500, 50),
    likes: randomNum(500),
    dislikes: randomNum(10),
}];
export const mostFollowed: PageDataType[] = [{
  id: "page_11",
  userImg: "assets/images/pages/ca_0501NID_Grasshopper_online.jpg",
  title: "Starving grasshoppers? How rising carbon dioxide levels may promote an ‘insect apocalypse’",
  userName: "Elizabeth Pennisi",
  userId: "uid_02",
  followers: randomNum(1000, 100),
  copies: randomNum(50),
  likes: randomNum(500),
  dislikes: randomNum(10),
}, {
    id: "page_12",
    userImg: "assets/images/pages/warm-month.png",
    title: "NASA: Last Month Was Earth’s Warmest May on Record",
    userName: "ImaGeo",
    userId: "uid_03",
    followers: randomNum(1000, 100),
    copies: randomNum(50),
    likes: randomNum(500),
    dislikes: randomNum(10),
  }, {
    id: "page_13",
    userImg: "assets/images/pages/Pollen_bubble_1280x720.jpg",
    title: "Drone-delivered soap bubbles could help pollinate flowers",
    userName: "Erik Stokstad",
    userId: "uid_01",
    followers: randomNum(1000, 100),
    copies: randomNum(50),
    likes: randomNum(500),
    dislikes: randomNum(10),
}]


export const pages = [];