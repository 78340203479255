<ok-back-button></ok-back-button>
<div class="page-actions">
  <button nz-button nzType="default"><i nz-icon nzType="team" nzTheme="outline"></i>Follow</button>
  <button nz-button nzType="default"><i nz-icon nzType="copy" nzTheme="outline"></i>Copy</button>
</div>
<h2 class="page-title">Vision to values</h2>
<div class="page-content">
  <h2>Vision</h2>
  
  <p>Start by describing your company's main vision. This is the primary goal that unites each product goal and team
    member's work.</p>
  
  <h2>Mission</h2>
  
  <p>Explain the goal for each of your company's products and how it helps achieve your company's main vision. Each
    product goal should be measurable and achievable.</p>
  
  <h2>Strategies</h2>
  
  <p>Identify your company's competitive advantages. Then decide how to transform those strengths into actionable
    strategies to help you achieve product goals.</p>
  
  <table style="width: 100%;">
    <thead>
      <tr>
        <th>Competitive advantage</th>
        <th>Product strategy</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="width: 50.0000%;">e.g., The company has a strong AI engineering team</td>
        <td style="width: 50.0000%;">e.g., The company can leverage AI to develop new products</td>
      </tr>
      <tr>
        <td style="width: 50.0000%;">
          <br>
        </td>
        <td style="width: 50.0000%;">
          <br>
        </td>
      </tr>
    </tbody>
  </table>
  
  <p>
    <br>
  </p>
  
  <h2>Objectives</h2>
  
  <p>Track your progress by aligning each product goal with a measurable metric.</p>
  
  <table style="width: 100%;">
    <thead>
      <tr>
        <th>Product goal</th>
        <th>Metric</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="width: 50.0000%;">e.g., Increase revenue</td>
        <td style="width: 50.0000%;">e.g., 40% increase in y/y revenue</td>
      </tr>
      <tr>
        <td style="width: 50.0000%;">
          <br>
        </td>
        <td style="width: 50.0000%;">
          <br>
        </td>
      </tr>
    </tbody>
  </table>
  
  <p>
    <br>
  </p>
  
  <h2>Tactics</h2>
  
  <p>List the tactics you're planning to execute your product strategies. Organize your list by prioritizing tactics that
    best help your company achieve its main vision.</p>
  
  <table style="width: 100%;">
    <thead>
      <tr>
        <th>Priority</th>
        <th>Product goal</th>
        <th>Tactic</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="width: 33.3333%;">1</td>
        <td style="width: 33.3333%;">e.g., Increase revenue</td>
        <td style="width: 33.2502%;">e.g. Launch premium tier</td>
      </tr>
      <tr>
        <td style="width: 33.3333%;">
          <br>
        </td>
        <td style="width: 33.3333%;">
          <br>
        </td>
        <td style="width: 33.2502%;">
          <br>
        </td>
      </tr>
    </tbody>
  </table>
  
  <p>
    <br>
  </p>
  
  <h2>Customer values</h2>
  
  <p>List the reasons why customers should use your company's products or services. Organize your list by prioritizing
    values that are most important to your customers and most beneficial to your business.</p>
  
  <table style="width: 100%;">
    <thead>
      <tr>
        <th style="width: 12.9828%;">Priority</th>
        <th style="width: 86.9099%;">Customer value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="width: 12.9828%;">1</td>
        <td style="width: 86.9099%;">e.g., Customers can get started on document collaboration faster</td>
      </tr>
      <tr>
        <td style="width: 12.9828%;">
          <br>
        </td>
        <td style="width: 86.9099%;">
          <br>
        </td>
      </tr>
    </tbody>
  </table>
  
  <p>
    <br>
  </p>
  
  <h2>Customer segments</h2>
  
  <p>List and organize the customer segments you're targeting. This will help you focus product strategies and prioritize
    tactics.</p>
  
  <table style="width: 100%;">
    <tbody>
      <tr>
        <td style="width: 12.4464%;">Priority</td>
        <td style="width: 87.4463%;">Customer segment</td>
      </tr>
      <tr>
        <td style="width: 12.4464%;">1</td>
        <td style="width: 87.4463%;">e.g., Customers can get started on document collaboration faster</td>
      </tr>
    </tbody>
  </table>


</div>
<div class="page-footer">
  <div nz-row nzJustify="space-between" class="margin-bottom-1">
    <div nz-col>
      <p>Created Date: <em>10.12.2020 8:53PM</em></p>
      <p>Updated Date: <em>10.12.2020 8:53PM</em></p>
    </div>
    <div nz-col>
      <button nz-button nzType="link" class="button-text">
        <i nz-icon nzType="team" nzTheme="outline"></i>
        <span class="margin-left-05">200</span>
      </button>
      <button nz-button nzType="link" class="button-text">
        <i nz-icon nzType="copy" nzTheme="outline"></i>
        <span class="margin-left-05">20</span>
      </button>
      <button nz-button nzType="link" class="button-text">
        <i nz-icon nzType="like" nzTheme="fill"></i>
        <span class="margin-left-05">136</span>
      </button>
      <button nz-button nzType="link" class="button-text">
        <i nz-icon nzType="dislike" nzTheme="fill"></i>
        <span class="margin-left-05">5</span>
      </button>
    </div>
  </div>
  <div nz-row nzGutter="16" nzAlign="middle" class="margin-bottom-1">
    <div nz-col>
      <p>Author:</p>
    </div>
    <div nz-col>
      <div nz-row nzGutter="16">
        <div nz-col>
          <nz-avatar nzIcon="user" [nzSize]="48" class="link" nzSrc="assets/images/users/user_1.jpg" routerLink="/users/uid_01"></nz-avatar>
        </div>
        <div nz-col>
          <h3 class="user-name">Adrian Cho</h3>
          <h4 class="user-job">Senior Manager at Globall Solutions Corporation</h4>
        </div>
      </div>
    </div>
  </div>
</div>