import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Page, PagesOrder, PagePosition } from '../models';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree/public-api';
import { find } from 'rxjs/operators';

import { PAGES_COLLAPSE_AFTER } from "../constants/page-config";

interface PutPageBody {
  id?: string;
  page: Page;
  pagePosition?: PagePosition;
}

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private http: HttpClient
  ) { }

  public getPages(projectId: string, orgId: string): Observable<{
    data: Array<Page>
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${ orgId }/projects/${ projectId }/pages`);
  }

  public getPagesWithOrder(projectId: string, orgId: string): Observable<{
    data: {
      pages: Array<Page>,
      pagesOrder: PagesOrder
    }
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${ orgId }/projects/${ projectId }/pages/?format=withPagesOrder`);
  }

  public get(model: {
    projectId: string,
    pageId: string,
    orgId: string
  }): Observable<{
    data: Page
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${ model.orgId }/projects/${ model.projectId }/pages/${ model.pageId }`);
  }

  public getWithContent(model: {
    projectId: string,
    pageId: string,
    orgId: string
  }): Observable<{
    data: Page
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/orgs/${ model.orgId }/projects/${ model.projectId }/pages/${ model.pageId }?format=withContent`);
  }

  public edit(page: Page): Observable<{
    data: Page
  }> {
    const data: {[key: string]: any} = {
      name: page.name ? page.name : null
    };
    if ("content" in page) {
      data.content = page.content;
    }
    if (page.visibility) {
      data.visibility = page.visibility;
      if (page.category) {
        data.category = page.category;
      }
      if (page.lang) {
        data.lang = page.lang;
      }
    }
    return this.http.patch<any>(`${environment.api}/api/v1/orgs/${ page.organizationId }/projects/${ page.projectId }/pages/${ page.id }`, data);
  }

  public add(page: Page, pagePosition?: PagePosition): Observable<{
    data: {
      page: Page,
      pagesOrder: PagesOrder[]
    }
  }> {
    return this.http.post<any>(`${environment.api}/api/v1/orgs/${ page.organizationId }/projects/${ page.projectId }/pages`, {
      page: {
        name: page.name,
      },
      pagePosition: pagePosition
    });
  }

  put(body: PutPageBody, ids: { orgId: string; projectId: string }): Observable<{
    data: {
      page: Page,
      pagesOrder?: PagesOrder[]
    }
  }> {
    const url = `${environment.api}/api/v1/orgs/${ids.orgId}/projects/${ids.projectId}/pages`;
    return this.http.put<any>(url, body);
  }

  public delete(model: {
    projectId: string,
    pageId: string,
    orgId: string
  }): Observable<{ data: PagesOrder }> {
    return this.http.delete<{ data: PagesOrder }>(`${environment.api}/api/v1/orgs/${ model.orgId }/projects/${ model.projectId }/pages/${ model.pageId }`);
  }

  transformToList(pages: Page[]): NzTreeNodeOptions[] {
    const sorted = pages.sort((page1, page2) => page1.updatedDate >= page2.updatedDate ? -1 : 1);
    const pageTree: NzTreeNodeOptions[] = sorted.map(page => {
      return {
        ...page,
        key: page.id,
        title: page.name,
        isLeaf: true,
        updatedDate: page.updatedDate,
        updatedBy: page.updatedBy
      }
    });
    
    return pageTree;
  }

  public transformToTree(pages: Array<Page>, pagesOrder: Array<PagesOrder>): Array<NzTreeNodeOptions> {
    const pageTree = new Array<NzTreeNodeOptions>();
    const expand = pages.length > PAGES_COLLAPSE_AFTER ? false : true;

    pagesOrder.forEach((pageOrder) => {
      const findPage = pages.find(x => x.id === pageOrder.id);
      pageTree.push({
        ...findPage,
        key: pageOrder.id,
        title: findPage ? findPage.name : '',
        expanded: expand,
        icon: 'book',
        selected: false,
        updatedDate: findPage.updatedDate,
        updatedBy: findPage.updatedBy,
        children: pageOrder.pages && pageOrder.pages.length ? this.transformToTree(pages, pageOrder.pages) : []
      });
    });

    return pageTree;
  }

  updatePageTreeNodeTitle(pageTree: NzTreeNodeOptions[], page: Page): NzTreeNodeOptions[] {
    const newPageTree: NzTreeNodeOptions[] = [];

    pageTree.forEach((treeNode) => {
      newPageTree.push({
        ...treeNode,
        title: page.id === treeNode.key ? page.name : treeNode.title,
        name: page.id === treeNode.key ? page.name : treeNode.title,
        children: treeNode?.children?.length ? this.updatePageTreeNodeTitle(treeNode.children, page) : []
      });
    });

    return newPageTree;
  }

  public addPageToTree(pagesTree: Array<NzTreeNodeOptions>, page: Page, parentId?: string): Array<NzTreeNodeOptions> {
    this.setUnselected(pagesTree);
    if (parentId) {
      const findPage = this.findPage(pagesTree, parentId);
      if (findPage) {
        findPage.children.unshift({
          key: page.id,
          title: page.name,
          expanded: true,
          icon: 'page',
          selected: true,
          children: []
        });
      }

      return pagesTree;
    }

    pagesTree.unshift({
      key: page.id,
      title: page.name,
      expanded: true,
      icon: 'page',
      selected: true,
      children: []
    });
    return pagesTree;
  }

  public setUnselected(pagesTree: Array<NzTreeNodeOptions>): void {
    for (let i=0; i<pagesTree.length; i++) {
      pagesTree[i].selected = false;
      if (!pagesTree[i].children || pagesTree[i].children.length < 1) continue;
      this.setUnselected(pagesTree[i].children);
    }
    return;
  }

  public savePagePosition(pagePosition: PagePosition, data: {
    projectId: string,
    orgId: string
  }): Observable<{
    data: PagesOrder[]
  }> {
    return this.http.patch<any>(`${environment.api}/api/v1/orgs/${ data.orgId }/projects/${ data.projectId }/pagesOrder`, pagePosition);
  }

  public findPage(pages: Array<NzTreeNodeOptions>, pageId: string): NzTreeNodeOptions {

    for (let i=0; i < pages.length; i++) {
      if (pages[i].key === pageId) {
        return pages[i];
      }

      if (pages[i].children.length < 1) continue;

      const findedPage = this.findPage(pages[i].children, pageId);
      if (findedPage) return findedPage;
    }

    return;
  }

  public findPageWithSuperParent(pages: Array<NzTreeNodeOptions>, pageId: string, superParent?: NzTreeNodeOptions): NzTreeNodeOptions {

    for (let i=0; i < pages.length; i++) {
      if (pages[i].key === pageId) {
        return pages[i];
      }

      if (pages[i].children.length < 1) continue;

      const findedPage = this.findPage(pages[i].children, pageId);
      if (findedPage) return pages[i];
    }

    return;
  }

}
