import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { newDocs } from "../../explore/mock-pages";
import { topContributors, UserDataType } from "../../explore/mock-users";

@Component({
  selector: 'ok-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less']
})
export class UserComponent {
  pages = newDocs;
  userId: string;
  user: UserDataType;
  
  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {
      this.userId = this.route.snapshot.params['userId'];
      const user = topContributors.find(user => user.id === this.userId);

      if (user) {
        this.user = user;
      } else {
        this.router.navigateByUrl("/404", { replaceUrl: true });
      }
  }

  navigateBack() {
    this.location.back();
  }
}
