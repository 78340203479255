import { Organization, Project, Page, PagesOrder, Permission } from "../models";
import { collective } from './collective-intelligence';
export const permissions: Permission[] = [
  // {
  //   id: "perm_1",
  //   userId: "usr_0",
  //   user: { nickname: "User" },
  //   projectId: "proj_0",
  //   project: {
  //     name: "Project 1",
  //     organizationId: "org_0"
  //   },
  //   role: "guest",
  //   createdBy: "User 1", createdDate: new Date(), updatedBy: "User 1", updatedDate: new Date()
  // },
  // {
  //   id: "perm_3",
  //   userId: "usr_0",
  //   user: { name: "User" },
  //   projectId: "proj_2",
  //   project: {
  //     name: "Project 2",
  //     organizationId: "org_0"
  //   },
  //   role: "guest",
  //   createdBy: "User 1", createdDate: new Date(), updatedBy: "User 1", updatedDate: new Date()
  // },
  // {
  //   id: "perm_4",
  //   userId: "usr_0",
  //   user: { name: "User" },
  //   projectId: "proj_5",
  //   project: {
  //     name: "Notes",
  //     organizationId: "org_3"
  //   },
  //   role: "guest",
  //   createdBy: "User 1", createdDate: new Date(), updatedBy: "User 1", updatedDate: new Date()
  // }
];

export const organizations: Organization[] = [
  { id: "org_0", name: "My Organization", createdBy: "User 1", createdDate: new Date(), updatedBy: "User 1", updatedDate: new Date() },
  { id: "org_1", name: "Apple", createdBy: "User 1", createdDate: new Date(), updatedBy: "User 1", updatedDate: new Date() },
  { id: "org_2", name: "Facebook", createdBy: "User 1", createdDate: new Date(), updatedBy: "User 1", updatedDate: new Date() },
  { id: "org_3", name: "My Personal", createdBy: "User 1", createdDate: new Date(), updatedBy: "User 1", updatedDate: new Date() },
];

const order_proj_0 = JSON.stringify([
  {
    id: "page_0",
    name: "Collective Intelligence",
  },
  {
    id: "page_25",
    name: "Typography",
  },
  {
    id: "page_10",
    name: "Competitive Analysis",
    pages: [
      { id: "page_11", name: "Competitive Analysis 1" }
    ]
  },
  {
    id: "page_12",
    name: "Customer Interview Reports",
    pages: [
      { id: "page_13", name: "Customer Interview Report 1" }
    ]
  },
  {
    id: "page_14",
    name: "Decisions",
    pages: [
      { id: "page_15", name: "Decision 1" },
      { id: "page_16", name: "Decision 2" },
      { id: "page_17", name: "Decision 3" }
    ]
  },
  {
    id: "page_18",
    name: "Goals Signals Measures",
    pages: [
      { id: "page_19", name: "Goals 1" }
    ]
  },
  {
    id: "page_20",
    name: "Vision To Values",
  },
  {
    id: "page_21",
    name: "Product Requirements",
    pages: [
      { id: "page_22", name: "Product Requirements 1" }
    ]
  },
  {
    id: "page_23",
    name: "Project Plan Template",
    pages: [
      { id: "page_24", name: "Project Plan 1" }
    ]
  },
  {
    id: "page_26",
    name: "Spreadsheet Example",
  },
  {
    id: "page_4",
    name: "Page 1",
    pages: [
      {
        id: "page_6",
        name: "Page 2",
        pages: [
          { id: "page_8", name: "Page 3" },
          { 
            id: "page_9_1", name: "Page 4",
            pages: [
              { id: "page_9_1_1", name: "Page 5" }
            ]
          }
        ]
      }
    ]
  }
] as PagesOrder[]);

const order_proj_4 = JSON.stringify([
  {
    id: "page_14",
    name: "Decisions",
    pagesOrder: [
      { id: "page_15", name: "Decision Title" }
    ]
  }
] as PagesOrder[]);

const order_proj_5 = JSON.stringify([
  {
    id: "page_27",
    name: "Goals",
  },
  {
    id: "page_28",
    name: "Books",
    pages: [
      { id: "page_29", name: "Business" },
      { id: "page_30", name: "Science" },
      { id: "page_31", name: "Fiction" }
    ]
  },
  {
    id: "page_32",
    name: "Places To Go",
  },
  {
    id: "page_33",
    name: "Info",
  },
  {
    id: "page_34",
    name: "Bookmarks",
  },
  {
    id: "page_35",
    name: "Burning Man",
  },
  {
    id: "page_36",
    name: "Passwords",
  },
  {
    id: "page_37",
    name: "XXX",
  },

] as PagesOrder[]);

export let projects: Project[] = [];
let projectIndex = 0;

for (const organization of organizations) {
  for (let i = 0; i < 3; i++) {
    const proj = {
      id: `proj_${projectIndex}`,
      name: ["Search Engine", "iOS", "Android"][i],
      type: "public",
      organizationId: organization.id,
      updatedDate: new Date(),
      updatedBy: "User 1",
      createdDate: new Date(),
      createdBy: "User 1"
    } as Project;

    // if (`proj_${projectIndex}` === "proj_0") {
    //   proj.pagesOrder = order_proj_0;
    // }  else if (`proj_${projectIndex}` === "proj_5") {
    //   proj.pagesOrder = order_proj_5;
    // } 
    // else {
    //   proj.pagesOrder = order_proj_4;
    // }
    
    projects.push(proj);
    projectIndex++;
  }
}

export let pages: Page[] = [];
let pageIdx = 0;


for (const project of projects) {
  for (let i = 0; i < 3; i++) {
    pages.push({
      id: `page_${pageIdx}`,
      name: ["Collective Intelligence", "Individual Opinion", "Common Sense"][i],
      projectId: [0, 1, 2, 4, 6, 8].includes(pageIdx) ? "proj_0" : project.id,
      organizationId: project.organizationId,
      content: Math.random().toString(36).replace(/[\d\.]/g, ''),
      updatedDate: new Date(Math.random() * 10e11),
      updatedBy: "User 1",
      createdDate: new Date(),
      createdBy: "User 1"
    } as Page)
    pageIdx++;
  }
}

pages[100] = {
  id: "page_9_1",
  name: "Collective Intelligence",
  projectId: "proj_0",
  organizationId: "org_0",
  content: "Page 4",
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[101] = {
  id: "page_9_1_1",
  name: "Collective Intelligence",
  projectId: "proj_0",
  organizationId: "org_0",
  content: "Page 5",
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[0] = {
  id: "page_0",
  name: "Collective Intelligence",
  projectId: "proj_0",
  organizationId: "org_0",
  content: collective,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import { competitiveAnalysisContent } from './CompetitiveAnalysisTemplate/CompetitiveAnalysisTemplate';
import { competitiveAnalysis1Content } from './CompetitiveAnalysisTemplate/index';

pages[10] = {
  id: "page_10",
  name: "Collective Intelligence",
  projectId: "proj_0",
  organizationId: "org_0",
  content: competitiveAnalysisContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[11] = {
  id: "page_11",
  name: "Competitive Analysis",
  projectId: "proj_0",
  organizationId: "org_0",
  content: competitiveAnalysis1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import { customerInterviewReportContent } from './CustomerInterviewReport/index';
import { customerInterviewReport1Content } from './CustomerInterviewReport/CustomerInterviewReport';

pages[12] = {
  id: "page_12",
  name: "Customer Interview Reports",
  projectId: "proj_0",
  organizationId: "org_0",
  content: customerInterviewReportContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[13] = {
  id: "page_13",
  name: "Customer Interview Reports 1",
  projectId: "proj_0",
  organizationId: "org_0",
  content: customerInterviewReport1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import {decisionsContent} from "./Decisions/index";
import {decision1Content} from "./Decisions/Decision";

pages[14] = {
  id: "page_14",
  name: "Decisions",
  projectId: "proj_0",
  organizationId: "org_0",
  content: decisionsContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[15] = {
  id: "page_15",
  name: "Decision 1",
  projectId: "proj_0",
  organizationId: "org_0",
  content: decision1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[16] = {
  id: "page_16",
  name: "Decision 2",
  projectId: "proj_0",
  organizationId: "org_0",
  content: decision1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[17] = {
  id: "page_17",
  name: "Decision 3",
  projectId: "proj_0",
  organizationId: "org_0",
  content: decision1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import {goalsSignalsMeasuresContent} from "./GoalsSignalsMeasures/index";
import {goals1Content} from "./GoalsSignalsMeasures/GoalsSignalsMeasures";

pages[18] = {
  id: "page_18",
  name: "Decisions",
  projectId: "proj_0",
  organizationId: "org_0",
  content: goalsSignalsMeasuresContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[19] = {
  id: "page_19",
  name: "Decision 1",
  projectId: "proj_0",
  organizationId: "org_0",
  content: goals1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import {visionToValuesContent} from "./ProductManagement/VisionToValues";

pages[20] = {
  id: "page_20",
  name: "Vision To Values",
  projectId: "proj_0",
  organizationId: "org_0",
  content: visionToValuesContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import {productRequirementsContent} from "./ProductRequirements/index";
import {productRequirements1Content} from "./ProductRequirements/ProductRequirements";

pages[21] = {
  id: "page_21",
  name: "Product Requirements",
  projectId: "proj_0",
  organizationId: "org_0",
  content: productRequirementsContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[22] = {
  id: "page_22",
  name: "Product Requirements 1",
  projectId: "proj_0",
  organizationId: "org_0",
  content: productRequirements1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import {projectPlanTemplateContent} from "./ProjectPlanTemplate/index";
import {projectPlanTemplate1Content} from "./ProjectPlanTemplate/ProjectPlanTemplate";

pages[23] = {
  id: "page_23",
  name: "Project Plan Template",
  projectId: "proj_0",
  organizationId: "org_0",
  content: projectPlanTemplateContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[24] = {
  id: "page_24",
  name: "Project Plan 1",
  projectId: "proj_0",
  organizationId: "org_0",
  content: projectPlanTemplate1Content,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

import {typographyContent} from './typography';

pages[25] = {
  id: "page_25",
  name: "Typography",
  projectId: "proj_0",
  organizationId: "org_0",
  content: typographyContent,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[26] = {
  id: "page_26",
  name: "Spreadsheet Example",
  projectId: "proj_0",
  organizationId: "org_0",
  content: `<h1>Spreadsheet Example</h1> <div class="ok-spreadsheet"></div>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[27] = {
  id: "page_27",
  name: "Goals",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1>Goals</h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[28] = {
  id: "page_28",
  name: "Books",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Books </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[29] = {
  id: "page_29",
  name: "Business",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Business </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[30] = {
  id: "page_30",
  name: "Science",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Science </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[31] = {
  id: "page_31",
  name: "Fiction",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Fiction </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[32] = {
  id: "page_32",
  name: "Places To Go",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Places To Go </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[33] = {
  id: "page_33",
  name: "Info",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Info </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[34] = {
  id: "page_34",
  name: "Bookmarks",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Bookmarks </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[35] = {
  id: "page_35",
  name: "Burning Man",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Burning Man </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[36] = {
  id: "page_36",
  name: "Passwords",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1> Oh no no no </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;

pages[37] = {
  id: "page_37",
  name: "X X X",
  projectId: "proj_5",
  organizationId: "org_3",
  content: `<h1>I can't believe you opened it</h1> <h1><span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f609.svg);">&nbsp;</span> </h1>`,
  updatedDate: new Date(Math.random() * 10e11),
  updatedBy: "User 1",
  createdDate: new Date(),
  createdBy: "User 1"
} as Page;