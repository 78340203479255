export const competitiveAnalysis1Content = `<h1>Competitive analysis template</h1>

<p>You’re going head-to-head with your competition to win the loyalty of the exact same customer base. This template can help you come out on top by walking you through a detailed analysis of what your competition has to offer, from pricing and messaging
    to how many followers they have on Instagram. By completing this competitive analysis, you’ll get a clear idea of how you can differentiate your company and rise above the crowd. How’s that for a one-two punch?!</p>

<h2>How to use the competitive analysis template</h2>

<h3>Step 1. Complete your competitor profile</h3>

<p>In the top table of the template, write in the competitor's name as well as the date the analysis was completed and who did it (so everybody knows who to approach with questions). In this same table, you’ll also rate at what tier this competitor weighs
    in: tier one for major competitors, tier two for moderate competitors, and tier three for minor competitors.</p>

<p>Underneath that, fill in a brief competitor profile. Here’s where you’ll cover the nuts and bolts information about that opposing company, such as their mission, key objectives, company size, and revenue.</p>

<h3>Step 2. Discover your competitive advantage</h3>

<p>The goal of the competitive analysis isn’t just to understand your competitor. It’s to figure out the ways in which you’re better than them so you can showcase those benefits. In the Competitive advantage section of the template, put on your critical
    thinking cap and identify your competitive edge. Do you have more advanced features? A longer history of proven success? Testimonials from trusted authorities in your industry? Whatever it is that makes you special, jot it down in this space.</p>

<h3>Step 3. Compare your target markets and market share</h3>

<p>You already know that you and your competitors are going after the same win, but in this section, you’re going to see just how closely your target markets align. Fill in the chart to identify the target market, specific verticals, and market share for
    your competitor. Then do the same for your own company to see where you overlap and where you differ. There’s also a section for additional commentary so that you have space to record your insights and observations as you go through this exercise.</p>

<h3>Step 4. Compare your product offerings</h3>

<p>It’s time to turn your attention to the star of the show: your product offering. Use this section to compare your product to your competitors’ by jotting down your product overview, positioning, and pricing and then doing the same for that opposing company.
    The bottom of the table has space for you to compare specific core features. This helps you to highlight what makes your product different, so you can emphasize that value in your marketing and leave your competitors in the dust.</p>

<h3>Step 5. Dig into your marketing strategies</h3>

<p>When you’re trying to get the word out about your product (and drown out your competitors), you need to be strategic about the marketing channels you’re using. This is the largest table in the template, and it gives you ample space to compare what your
    competitor is doing in those channels with your own strategies. Include as many details for each channel as possible, from the number of their Twitter followers to the words they use to describe themselves. Take your time here, and you’ll be sure
    to uncover some clever ways to make your marketing stand out.</p>

<h3>Step 6. Conduct a SWOT analysis</h3>

<p>Wrap up your competitive analysis with some self-reflection. The final table is a SWOT analysis where you’ll get honest about your company or product’s strengths (you have an established customer base), weaknesses (you’re more expensive than your competitors),
    opportunities (there’s an entire customer vertical that you haven’t tapped into yet), and threats (your competitor is rolling out new features). Identifying risks and areas where you’re falling short can be sort of brutal, but it’s valuable information
    to have as you figure out how you’ll rise to the top of the heap when the competition is stiff.</p>
`