export const productRequirementsContent = `<h1>Product requirements template</h1>

<p>As excited as you are to roll out a new product or feature, you don’t want your enthusiasm to sabotage your ability to make strategic decisions. You need to ensure that whatever you’re launching is well thought out and has a real use case. Use this template
    to flesh out your product requirements with your development team and product designers. The sections of this template will walk you through the assumptions you're making, user stories, UX design, scoping, and more.</p>

<h2>How to use the product requirements template</h2>

<h3>Step 1. Cover the basics</h3>

<p>Set the scene by using the top table of the template to lay out the details of your new product or feature. What’s your target release date? What’s the current status? Who is on the core team? You can type /date to quickly add your target release date,
    "/jira" to link directly to Jira epics or issues, and @ mention team members to keep everybody in the loop.</p>

<h3>Step 2. Outline your objective and success metrics</h3>

<p>You aren’t launching this new product or feature for the sake of doing so (at least, you shouldn’t be). In the Objective section of the template, provide a brief explanation for how this project supports your organization’s larger goals. Basically, why
    should this new feature or product exist in the first place? Then, in the Success metrics table, list your product or feature-specific goals as well as the metrics you’ll use to monitor your success. For example, maybe you want to simplify the user
    experience and you’ll know you’ve done so when your customer satisfaction scores increase by at least 15%.</p>

<h3>Step 3. List your assumptions and options to address them</h3>

<p>This product or feature is entirely new, which means you don’t have a ton of existing information to work off of. However, you do have assumptions – and those can be surprisingly powerful for informing your next steps. In the Assumptions section of the
    template, jot down any assumptions you have about your users, technical constraints, and business goals. Do you think most users will access this feature from a tablet? Note that here.&nbsp;</p>

<p>With those assumptions in mind, you’ll use the&nbsp;Options&nbsp;table to map out all of the product requirements you’ve considered. If most users will utilize this feature on a tablet, then one requirement is that you need it to be mobile responsive.
    In addition to stating the requirement itself, this table also has spots for you to come up with a user story (i.e., John is a PM who wants to check his team’s progress from the train station), assign a level of importance, note any Jira issues, and
    record any other notes you need to keep handy.</p>

<h3>Step 4. Add supporting documentation</h3>

<p>There’s a lot that goes into pulling together a new feature or product, and it’s smart to keep that information consolidated so everybody has a single source of truth and can find what they need. Use this section of the template to add mockups, diagrams,
    or visual designs related to the product requirements you’ve outlined. Having all of those in one place immediately gives everybody the context they need.</p>

<h3>Step 5. Anticipate open questions and scope creep</h3>

<p>Sometimes the process of launching a feature or product seems like a never-ending series of questions. As soon as you address one, five more seem to crop up. This table gives you a spot to jot down those questions as they come to you (for example, how
    will you make your users aware of this new feature?) so you don’t lose track of them. When you eventually come up with a suitable answer, there’s a space to record that information as well as the date that you answered it so you can track your progress
    and the evolution of your product.</p>

<p>Finally, we know that the words “scope creep” alone are enough to send a shiver down your spine, and one of the best ways to prevent it is to be proactive. Use this final section of the template to list what’s out of scope for this feature or release.
    Be as specific as possible (and continue adding to this section as you move forward) so that everybody knows where the line is.</p>

<p>
    <br>
</p>`