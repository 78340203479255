import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) {

  }

  public getUserInfo(userUid: string): Observable<User> {
    return this.http.get<User>(`${environment.api}/api/v1/users/${userUid}`);
  }

  public updateUserInfo(user: User): Observable<any> {
    return this.http.patch<any>(`${environment.api}/api/v1/users/${user.id}`, user);
  }

  public createUser(user: User): Observable<any> {
    return this.http.post<any>(`${environment.api}/api/v1/users`, user);
  }

  public searchUserByNameAndEmail(text: string): Observable<{
    data: Array<User>
  }> {
    return this.http.get<any>(`${environment.api}/api/v1/users?search=` + text);
  }
}
