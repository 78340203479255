import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  NzModalModule,
  NzIconModule,
  NzMenuModule,
  NzLayoutModule,
  NzDropDownModule,
  NzButtonModule,
  NzRateModule,
  NZ_I18N,
  en_US,
  NzEmptyModule
} from 'ng-zorro-antd';
import { SearchOutline, TeamOutline, CopyOutline, LikeFill, DislikeFill, LeftOutline, GlobalOutline, InstagramOutline, LinkedinOutline, TwitterOutline, ClearOutline, LockOutline } from '@ant-design/icons-angular/icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { SharedModule } from './shared.module';
import { AngularFireModule } from '@angular/fire';


import { AppComponent } from './app.component';

// localstorage
import { LocalStorageModule, LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../environments/environment';
import { AuthInterceptorModule } from './interceptors/auth.interceptor.module';
// import { HeaderComponent } from './components';


const icons = [SearchOutline, TeamOutline, CopyOutline, LikeFill, DislikeFill, LeftOutline, GlobalOutline, InstagramOutline, LinkedinOutline, TwitterOutline, ClearOutline, LockOutline];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule,
    AuthInterceptorModule.forRoot(),
    SharedModule,
    LocalStorageModule.forRoot({
      prefix: 'ok-root',
      storageType: 'localStorage'
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // ant design
    NzModalModule,
    NzLayoutModule,
    NzMenuModule,
    NzDropDownModule,
    NzButtonModule,
    NzRateModule,
    NzIconModule.forRoot(icons)
  ],
  providers: [
    LocalStorageService,
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
