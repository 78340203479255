import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';

@NgModule({
    imports: [CommonModule],
    providers: []
})
export class AuthInterceptorModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: AuthInterceptorModule,
            providers: [
              {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor,
                multi: true
              }
            ]
        };
    }
}
