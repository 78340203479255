export const customerInterviewReportContent = `
<h1>Customer interview report template</h1>

<p>If you’re not chatting with your customers on the regular, you’re missing out on actionable, insightful nuggets that could transform your product, service, operations, or growth of your company. Build this practice at your company with the customer interview
    report template. This template houses the notes you’ll take during your interview and helps you organize them into high-level observations and opportunities you can take back to the wider team.</p>

<h2>How to use the customer interview report template</h2>

<h3>Step 1. Create interview questions</h3>

<p>It would be a mistake (on so many levels) to just call up a customer and start firing off questions. Spend some time brainstorming on your own or with your team at least 20 possible questions, depending on how much time the customer has to spare. It’s
    a good idea to create a shortlist of the 8–10 that will give you the most bang for your buck just in case their time is limited or they have to cut the interview short.</p>

<h3>Step 2. Choose a customer with something to say</h3>

<p>Check in with your customer-facing teams like sales, support, and account management to get their recommendations on which customers will have the most valuable feedback. Look for customers who can share the good, the bad, and the ugly so that you gain
    well-rounded intel. Book a time on their calendar and have this template on your screen so you can record their thoughts in real-time.</p>

<h3>Step 3. Conduct the interview</h3>

<p>You’ll see that the template allows you to add your questions and their answers directly into the document. Use that area to record their answers as verbatim as possible. Save the specifics about the impact your product or service has on your customer’s
    day-to-day for the Use cases section. These interviews are gifts that keep on giving: not only can you get firsthand evaluation of your product or service, but you can also use this time to gather and ask permission to use testimonials. You may even
    learn about use cases you hadn’t thought of or regular users that weren’t on your radar.</p>

<h3>Step 4: Highlight your big observations</h3>

<p>Once the interview is over, take a moment to jot down the most meaningful points your customer made and the actions your team might take to deliver a better product or service. Put these in the Observation section. If your customer has Jira tickets in
    with your support or IT teams, add them here as well.</p>

<h3>Step 5: Sum up your key takeaways</h3>

<p>Loop back to the top of the template to the Top takeaways area to write a summary of the interview. Pull from your observations and use cases to find the most salient and must-know details.</p>

<p>
    <br>
</p>
`