import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organization, Permission, User } from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree/public-api';
import { ProjectService } from './project.service';

function sortByABC(a: any, b: any): number {
  const key = "project" in a ? "project" : "organization";
  const aName = a[key].name.toLowerCase();
  const bName = b[key].name.toLowerCase();
  if (aName < bName) { return -1; }
  if (aName > bName) { return 1; }
  return 0;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient,
    private projectService: ProjectService
  ) { }

  public add(orgName: string): Observable<{
    data: {
      organization: Organization,
      permissions: Permission
    }
  }> {
    return this.http.post<any>(`${environment.api}/api/v1/orgs`, {
      name: orgName
    });
  }

  public edit(id: string, orgName: string): Observable<any> {
    return this.http.patch<any>(`${environment.api}/api/v1/orgs/${ id }`, {
      name: orgName
    });
  }

  public delete(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.api}/api/v1/orgs/${ id }`);
  }

  public transformToTree(permissions: Array<Permission>): { [key: string]: NzTreeNodeOptions } {
    let orgTree: { [key: string]: NzTreeNodeOptions } = {};
    const orgPermissions = permissions.filter(x => x.organization).sort(sortByABC);
    const projectPermissions = permissions.filter(x => x.project).sort(sortByABC);

    orgPermissions.forEach((permission) => {
      orgTree[permission.organization.id] = {
        key: permission.organization.id,
        title: permission.organization.name,
        expanded: true,
        icon: "home",
        children: this.projectService.transformToTree(projectPermissions.filter(x => x.project.organizationId === permission.organization.id))
      };
    });
    return orgTree;
  }

  public editMember(member: {
    userNickname: string,
    role: string,
    orgId: string,
    orgName: string,
    permissionId: string
  }): Observable<{
    data: any
  }> {
    return this.http.patch<any>(`${environment.api}/api/v1/orgs/${member.orgId}/permissions/${member.permissionId}`, {
      userNickname: member.userNickname,
      role: member.role,
      orgName: member.orgName
    });
  }

  public addMember(member: {
    orgId: string,
    role: string,
    orgName: string,
    user: User
  }): Observable<{
    data: Permission
  }> {
    return this.http.post<any>(`${environment.api}/api/v1/orgs/${member.orgId}/permissions`, member);
  }

  public deleteMember(orgId: string, permissionId: string): Observable<any> {
    return this.http.delete<any>(`${environment.api}/api/v1/orgs/${orgId}/permissions/${permissionId}`);
  }

}
