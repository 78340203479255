const userMock = {
  id: "uid_01",
  name: "Jimmy Doe Clark",
  userName: "@jimmy",
  location: "New York, USA",
  social: {
    email: "",
    site: "",
    instagram: "",
    linkedIn: "",
    twitter: ""
  },
  job: "Senior Manager at Globall Solutions Corporation",
  image: "assets/images/users/user_1.jpg",
  bio: "At the position of managing the Globall Solutions Corp. finance operations for the foreign partners across the globe.",
  followers: 200,
  pagesCopied: 20,
  likes: 136,
  dislikes: 5
};

export type UserDataType = typeof userMock;

export const topContributors: UserDataType[] = [
  userMock,
  {
    id: "uid_02",
    name: "Lenoe Smith",
    userName: "@lsmith",
    location: "Otawa, Canada",
    social: {
      email: "",
      site: "",
      instagram: "",
      linkedIn: "",
      twitter: ""
    },
    job: "Marketing consultant",
    image: "assets/images/users/user_2.jpg",
    bio: "I know all about Marketing",
    followers: 10,
    pagesCopied: 0,
    likes: 48,
    dislikes: 13
  },
  {
    id: "uid_03",
    name: "Quique Gonzales Moreno",
    userName: "@moreno",
    location: "San Francisco, USA",
    social: {
      email: "",
      site: "",
      instagram: "",
      linkedIn: "",
      twitter: ""
    },
    job: "Tech-support specialist",
    image: "assets/images/users/user_3.jpg",
    bio: "10 years experience in the field of technical support",
    followers: 80,
    pagesCopied: 21,
    likes: 70,
    dislikes: 5
  }
]