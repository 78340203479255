

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { from } from 'rxjs';
import { StateService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private stateService: StateService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        return from(this.checkToken(req, next));
    }

    private async checkToken(req, next) {
        const token = await this.stateService.getTokenFromFirebase();
        if (token) {
            let request = req.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'application/json'
                }
            });
            return next.handle(request).toPromise();
        }
        return next.handle(req).toPromise();
    }
}
