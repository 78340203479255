import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

import { SafePipe } from "./pipes";
import { MyLocalStorageService } from './services/local-storage.service';
import { AuthGuard } from './resolvers/auth.guard';

import { NotFoundComponent, BackButtonComponent } from './components';
import { NzIconModule, NzSpinModule, NzEmptyModule, NzMessageService, NzNoAnimationModule, NzSkeletonModule } from 'ng-zorro-antd';

import { UserOutline } from '@ant-design/icons-angular/icons';
import { ProjectService } from './services';
const icons = [UserOutline];


const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzGridModule,
  NzDropDownModule,
  NzInputModule,
  NzCheckboxModule,
  NzButtonModule,
  NzLayoutModule,
  NzSelectModule,
  NzFormModule,
  NzRadioModule,
  NzDatePickerModule,
  NzTableModule,
  NzCardModule,
  NzIconModule,
  NzSpinModule,
  NzEmptyModule,
  NzNoAnimationModule,
  NzSkeletonModule,
  NzTabsModule
];

const components = [
  NotFoundComponent,
  BackButtonComponent
]

@NgModule({
  declarations: [
    SafePipe,
    ...components
  ],
  imports: [
    ...modules,
    NzIconModule.forChild(icons),
  ],
  exports: [
    SafePipe,
    ...modules,
    ...components,
  ],
  providers: [
    MyLocalStorageService,
    ProjectService,
    AuthGuard,
    NzMessageService
  ]
})
export class SharedModule { }
