<div class="empty-center-block">
    <nz-empty
        nzNotFoundImage="/assets/images/not-found/notfound2.jpg"
        [nzNotFoundContent]="contentTpl">
        <ng-template #contentTpl>
            <b>Not Found!</b><br/>
            <span>That page doesn't exist or is unvaliable.</span>
        </ng-template>
        <ng-template #footerTpl>
            <!-- <button nz-button nzType="primary"
                (click)="back()">
                Go back to Home
            </button> -->
        </ng-template>
    </nz-empty>
</div>
