<ok-back-button></ok-back-button>
<div class="user-info">
  <nz-avatar nzIcon="user" [nzSize]="150" [nzSrc]="user.image" class="margin-bottom-1"></nz-avatar>
  <h1>{{ user.name }}</h1>
  <p>{{ user.userName }}</p>
  <p>{{ user.location }}</p>
  <p class="margin-bottom-2">{{ user.job }}</p>

  <div nz-row nzJustify="space-between" class="margin-bottom-2">
    <div nz-col class="social-actions">
      <a [href]="('mail:' + user.social?.email) | safe: 'url'">
        <button nz-button nzType="default">
          <i nz-icon>@</i>
        </button>
      </a>
      <a [href]="user.social?.site || ('/users/' + userId)">
        <button nz-button nzType="default">
          <i nz-icon nzType="global" nzTheme="outline"></i>
        </button>
      </a>
      <a [href]="user.social?.instagram || 'https://www.instagram.com'">
        <button nz-button nzType="default">
          <i nz-icon nzType="instagram" nzTheme="outline"></i>
        </button>
      </a>
      <a [href]="user.social?.linkedIn || 'https://www.linkedin.com'">
        <button nz-button nzType="default">
          <i nz-icon nzType="linkedin" nzTheme="outline"></i>
        </button>
      </a>
      <a [href]="user.social?.twitter || 'https://twitter.com'">
        <button nz-button nzType="default">
          <i nz-icon nzType="twitter" nzTheme="outline"></i>
        </button>
      </a>
    </div>
    <div nz-col>
      <button nz-button nzType="default"><i nz-icon nzType="team" nzTheme="outline"></i>Follow</button>
    </div>
  </div>

  <div class="user-bio">
    <p><b>Description</b></p>
    <p>{{ user.bio }}</p>
  </div>

  <div nz-row nzGutter="16" nzJustify="center" class="margin-bottom-2">
    <div nz-col>
      <i nz-icon nzType="team" nzTheme="outline"></i>
      <span class="margin-left-05">{{ user.followers }}</span>
    </div>
    <div nz-col>
      <i nz-icon nzType="copy" nzTheme="outline"></i>
      <span class="margin-left-05">{{ user.pagesCopied }}</span>
    </div>
    <div nz-col>
      <i nz-icon nzType="like" nzTheme="fill"></i>
      <span class="margin-left-05">{{ user.likes }}</span>
    </div>
    <div nz-col>
      <i nz-icon nzType="dislike" nzTheme="fill"></i>
      <span class="margin-left-05">{{ user.dislikes }}</span>
    </div>
  </div>
</div>

<div class="user-data">
  <nz-tabset [nzTabPosition]="'top'" [nzType]="'card'">
    <nz-tab nzTitle="Pages">
      <div class="cards-inline text-left">
        <ok-page-card class="cards-inline__item" *ngFor="let page of pages" [page]="page">
        </ok-page-card>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Organizations">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </nz-tab>
    <nz-tab nzTitle="Activity">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </nz-tab>
  </nz-tabset>
</div>