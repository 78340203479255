<nz-card *ngIf="page; else empty" nzHoverable nzBordered="false" class="card-bordered min-height-200">
  <div class="page-card" routerLink="/pages/{{ page?.id }}">
    <h3 class="page-card__title" [title]="page.title">{{ page.title }}</h3>
    <div class="page-card__bottom">
      <h4 class="page-card__subtitle" [title]="page.userName" routerLink="/users/{{ page?.userId }}">
        <nz-avatar nzIcon="user" [nzSrc]="page.userImg"></nz-avatar>
        <span class="margin-left-05">{{ page.userName }}</span>
      </h4>
      <div nz-row nzGutter="16">
        <div nz-col>
          <i nz-icon nzType="team" nzTheme="outline"></i>
          <span class="margin-left-05">{{ page.followers }}</span>
        </div>
        <div nz-col>
          <i nz-icon nzType="copy" nzTheme="outline"></i>
          <span class="margin-left-05">{{ page.copies }}</span>
        </div>
        <div nz-col>
          <i nz-icon nzType="like" nzTheme="fill"></i>
          <span class="margin-left-05">{{ page.likes }}</span>
        </div>
        <div nz-col>
          <i nz-icon nzType="dislike" nzTheme="fill"></i>
          <span class="margin-left-05">{{ page.dislikes }}</span>
        </div>
      </div>
    </div>
  </div>
</nz-card>

<ng-template #empty>
  <div class="empty-center-block">
    <nz-empty></nz-empty>
  </div>
</ng-template>