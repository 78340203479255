import { Component, Input } from '@angular/core';
import { PageDataType } from "../mock-pages";

@Component({
  selector: 'ok-page-card',
  templateUrl: './page-card.component.html',
  styleUrls: ['./page-card.component.less']
})
export class PageCardComponent {
  @Input() page: PageDataType;
}