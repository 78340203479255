export const competitiveAnalysisContent = `<h1>Competitive analysis 1</h1>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 19.7425%; background-color: rgb(239, 239, 239);">Competitor</td>
            <td style="width: 80.1502%;">Company &amp; Product Name</td>
        </tr>
        <tr>
            <td style="width: 19.7425%; background-color: rgb(239, 239, 239);">Date created</td>
            <td style="width: 80.1502%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 19.7425%; background-color: rgb(239, 239, 239);">Analysis by</td>
            <td style="width: 80.1502%;">@mention owner</td>
        </tr>
        <tr>
            <td style="width: 19.7425%; background-color: rgb(239, 239, 239);">Tier (1-3)</td>
            <td style="width: 80.1502%;">Tier 1 - major competitor / Tier 2 - moderate competitor / Tier 3 - minor competitor</td>
        </tr>
    </tbody>
</table>

<h2>
    <br>
</h2>

<h2>Competitor profile</h2>

<ul>
    <li><strong>Company mission:</strong> e.g., Unleash the potential of every team</li>
    <li><strong>Key objectives:</strong> e.g., maintain majority market share</li>
    <li><strong>Capabilities:</strong> e.g., Key partnerships in the enterprise space</li>
    <li><strong>Company size:&nbsp;</strong></li>
    <li><strong>Revenue:</strong></li>
</ul>

<h2>Our competitive advantage</h2>

<ul>
    <li>Key differentiators: e.g., Powerful features for highly technical users</li>
</ul>

<h2>Target market &amp; market share</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 12.3391%; background-color: rgb(239, 239, 239);">
                <br>
            </td>
            <td style="width: 28.5408%; background-color: rgb(239, 239, 239);">Competitor</td>
            <td style="width: 34.1201%; background-color: rgb(239, 239, 239);">Your company</td>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Notes</td>
        </tr>
        <tr>
            <td style="width: 12.3391%;">Target market</td>
            <td style="width: 28.5408%;">
                <br>
            </td>
            <td style="width: 34.1201%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 12.3391%;">Verticals</td>
            <td style="width: 28.5408%;">
                <br>
            </td>
            <td style="width: 34.1201%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 12.3391%;">Market share</td>
            <td style="width: 28.5408%;">
                <br>
            </td>
            <td style="width: 34.1201%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<h2>
    <br>
</h2>

<h2>Product offering</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">
                <br>
            </td>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Competitor</td>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Your company</td>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Commentary</td>
        </tr>
        <tr>
            <td style="width: 25.0000%;">Product overview</td>
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0000%;">Positioning/Category</td>
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0000%;">Pricing</td>
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td colspan="4" style="width: 99.8927%; background-color: rgb(239, 239, 239);">Core feature comparison</td>
        </tr>
        <tr>
            <td style="width: 25.0000%;">Feature 1</td>
            <td style="width: 25.0000%;">Yes/No</td>
            <td style="width: 25.0000%;">Yes/No</td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0000%;">Feature 2</td>
            <td style="width: 25.0000%;">Yes/No</td>
            <td style="width: 25.0000%;">Yes/No</td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Marketing Strategies</h2>

<p>Fill in the table below with information about the tools your competitor is using and how they're using them. Include specific details, such as the number of related properties, quality, and target audience.</p>

<p>To add visuals, type /image or drag an image onto the page.</p>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">
                <br>
            </td>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Competitor</td>
            <td style="width: 24.8659%; background-color: rgb(239, 239, 239);">Your company</td>
            <td style="width: 25%; background-color: rgb(239, 239, 239);">Notes and Links</td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Overall strategy</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Website</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Blog/Content</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Social presence</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">SEO</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Online advertising</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Offline advertising</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Videos and webinars</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Major events</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Customer resources</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Customer review listings</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Press releases and mentions</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Customer engagement</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Social proof (customer logos)</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 25.0268%; background-color: rgb(239, 239, 239);">Partnerships and investments</td>
            <td style="width: 25.0268%;">
                <br>
            </td>
            <td style="width: 24.8659%;">
                <br>
            </td>
            <td style="width: 25.0000%;">
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>SWOT analysis</h2>

<p>List your product or company's strengths, weaknesses, opportunities, and threats in the table below</p>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Strengths</td>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Opportunities</td>
        </tr>
        <tr>
            <td style="width: 50.0000%;">

                <ul>
                    <li>strength 1</li>
                    <li>strength 2</li>
                </ul>
            </td>
            <td style="width: 50.0000%;">

                <ul>
                    <li>opportunity 1</li>
                    <li>opportunity 2</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Weaknesses</td>
            <td style="width: 50%; background-color: rgb(239, 239, 239);">Threats</td>
        </tr>
        <tr>
            <td style="width: 50.0000%;">

                <ul>
                    <li>weakness 1</li>
                    <li>weakness 2</li>
                </ul>
            </td>
            <td style="width: 50.0000%;">

                <ul>
                    <li>threats 1</li>
                    <li>threats 2</li>
                </ul>
            </td>
        </tr>
    </tbody>
</table>
`
