export const decision1Content = `
<h1>Decision Title</h1>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 15.6652%; background-color: rgb(239, 239, 239);">Status</td>
            <td style="width: 84.2275%;">NOT STARTED / STARTED / APPROVED</td>
        </tr>
        <tr>
            <td style="width: 15.6652%; background-color: rgb(239, 239, 239);">Impact</td>
            <td style="width: 84.2275%;">HIGH / MEDIUM / LOW</td>
        </tr>
        <tr>
            <td style="width: 15.6652%; background-color: rgb(239, 239, 239);">Driver</td>
            <td style="width: 84.2275%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 15.6652%; background-color: rgb(239, 239, 239);">Responsible</td>
            <td style="width: 84.2275%;">@mention responsible person</td>
        </tr>
        <tr>
            <td style="width: 15.6652%; background-color: rgb(239, 239, 239);">Due Date</td>
            <td style="width: 84.2275%;">When does this decision need to be made by?</td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Context</h2>

<p>Provide context on the decision the team needs to make. Include links to relevant research, pages, and related decisions, as well as information on constraints or challenges that may impact the outcome.</p>

<h2>Options considered</h2>

<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 11.4101%; background-color: rgb(239, 239, 239);">
                <br>
            </td>
            <td style="width: 55.2207%; background-color: rgb(239, 239, 239);">Option 1</td>
            <td style="width: 33.3333%; background-color: rgb(239, 239, 239);">Option 2</td>
        </tr>
        <tr>
            <td style="width: 11.4101%; background-color: rgb(239, 239, 239);">Description</td>
            <td style="width: 55.2207%;">
                <br>
            </td>
            <td style="width: 33.3333%;">
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 11.4101%; background-color: rgb(239, 239, 239);">Pros</td>
            <td style="width: 55.2207%;">

                <ul>
                    <li>...</li>
                    <li>...</li>
                </ul>
            </td>
            <td style="width: 33.3333%;">

                <ul>
                    <li>...</li>
                    <li>...</li>
                </ul>
                <br>
            </td>
        </tr>
        <tr>
            <td style="width: 11.4101%; background-color: rgb(239, 239, 239);">Cons</td>
            <td style="width: 55.2207%;">

                <ul>
                    <li>...</li>
                    <li>...</li>
                </ul>
                <br>
            </td>
            <td style="width: 33.3333%;">

                <ul>
                    <li>...</li>
                    <li>...</li>
                </ul>
                <br>
            </td>
        </tr>
    </tbody>
</table>

<p>
    <br>
</p>

<h2>Proposal</h2>

<p>Proposal by the author</p>

<h2>Outcome</h2>

<p>Summarize the outcome below</p>
`
