import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from "rxjs";
import * as firebase from 'firebase/app';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import users from '../mockdata/users';
import { MyLocalStorageService } from './local-storage.service';
import { LocalUser, User } from '../models/user';
import { first } from 'rxjs/operators';

declare var Tribute: any;

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private subject = new Subject<any>();
  public observable$ = this.subject.asObservable();

  private _user: User = {
    id: null,
    nickname: "",
    email: ""
  };

  public userDataLoaded = false;

  public exploreCategoryValues: string[] | null = null;

  private commentsPosition: "bottom" | "side" | "float" = "bottom";

  public featureFlags: any = {};

  constructor(
    private router: Router,
    private localStorageService: MyLocalStorageService,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    this.user = this.localStorageService.getUser();

    const commentsPos = this.localStorageService.getCommentsPosition() || 'bottom';
    this.setCommentsPosition(commentsPos);

    this.observable$.subscribe((data: { mode: string, value: string, [key: string]: any }) => {
      console.log('state.service -> subscribe: data', data);
      if (data.mode === 'state') {
        const functions: { [key: string]: any } = {
          setUser: () => {
            if (data.user) {
              this.user = data.user;
            }
            this.userDataLoaded = true;
            this.router.navigate(['/orgs']);
          },
          updateUser: () => {
            if (data.user) {
              this.user = data.user
            }
          },
          orgsLoaded: () => {
            this.userDataLoaded = true;
          }
        };

        const func = functions[data.value];

        if (func) {
          func();
        }
      }
    });
  }

  set user(user: LocalUser) {
    this._user = user;
    this.localStorageService.setUser(user);
  }

  get userId(): string {
    return this._user?.id;
  }

  get nickname(): string {
    return this._user?.nickname;
  }

  get userEmail(): string {
    return this._user?.email;
  }

  get userCompany(): string {
    return this._user?.company || "";
  }

  get userFirstName(): string {
    return this._user?.firstName || "";
  }

  get userLastName(): string {
    return this._user?.lastName || "";
  }

  get userWebsite(): string {
    return this._user?.website || "";
  }

  get userSignedIn(): boolean {
    return firebase.auth().currentUser ? true : false;
  }

  get commentsAside(): boolean {
    return this.commentsPosition === 'side';
  }

  get commentsBottom(): boolean {
    return this.commentsPosition === 'bottom';
  }

  get commentsFloat(): boolean {
    return this.commentsPosition === 'float';
  }

  public async notifyAll(data: any): Promise<any> {
    if (data) {
      this.subject.next(data);
      const userFirebase = firebase.auth().currentUser;
      if (data.user && data.user.name && !userFirebase.displayName) {
        await userFirebase.updateProfile({
          displayName: data.user.name
        });
      }
    }
  }

  public signIn(
      email: string,
      password: string
    ): any {
    return this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      return this.afAuth.signInWithEmailAndPassword(email, password);
    });
  }

  public signInCustom(token: string): any {
    return this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      return this.afAuth.signInWithCustomToken(token);
    });
  }

  public getAuthUser(): Promise<firebase.User> {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  public signOut() {
    firebase.auth().signOut().then((res) => {
      this.user = { id: null, nickname: "", email: "" };
      this.localStorageService.clearStorage();
      this.userDataLoaded = false;
      this.router.navigate(["/account"]);
    }).catch(function(err) {
      console.log(err);
    });
  }

  public setCommentsPosition(type: "bottom" | "side" | "float") {
    this.commentsPosition = type;
    this.localStorageService.setCommentsPosition(type);
  }

  public async getTokenFromFirebase() {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      return token;
    }

    return null;
  }

  public async getNewTokenFromFirebase() {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken(true);
      return token;
    }

    return null;
  }

  featureCheck(feature: string): boolean {
    return (localStorage[feature] === "true" || localStorage["f.all"] === "true");
  }
}
