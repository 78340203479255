export const goalsSignalsMeasuresContent = `
<h1>Goals, signals, measures template</h1>

<p>Clear the noise and know what success looks like</p>

<p>Is there any point in setting a goal if you don’t know when you’ve reached it? If you have a lot of big dreams but very few milestones that prove your success, you’re in the right spot. With the Goals, signals, and measures template, you can set the goals
    that’ll keep you focused on the most valuable projects, define signals that indicate you're on the right path, and choose quantifiable success measures so you'll know when you've reached your destination.</p>

<p>How to use the goals, signals, measures template</p>

<h2>Step 1. Pick a direction</h2>

<p>First, figure out your team’s main objective and timetable for reaching it. What as a company and a team are you trying to achieve and by when? You’ll use this as your North Star when you gather the team to brainstorm the steps you should take to achieve
    the specific goals of your project.</p>

<p>Step 2. A sticky for your thoughts</p>

<p>The next step is to shake loose everyone’s ideas about your goals. Using sticky notes, take about 15 minutes to have each member write down what they hope to gain.</p>

<p>Step 3. Less is more</p>

<p>Likely your brainstorm yielded a pile of sticky notes all covered with viable ideas. Take 10 minutes to narrow them down to the three that will provide the most value. After the team has decided, pull out the Goals, Signals, and Measures template and
    write those three down in the “Pursuing” area. Store all those other ideas in the “Not pursuing” area so that you have a record and can revisit them as possibilities at another time.</p>

<p>Step 4: See the signs</p>

<p>For each goal, take 5 minutes to brainstorm signals that’ll let you know you’re on the path to success. For example, if one of your goals is to increase the market share for your product, you might watch for increased traffic to your website as a signal
    you’re on the right track. Create a couple of signals for each goal.</p>

<p>Step 5. Measure it</p>

<p>A measure is a quantitative indicator of your success. Obviously, a measure should be measurable. “Our customers are mentioning our website more often” just won’t do. Instead, try something like “Clicks to our landing pages increase by 3% by the end of
    Q4.” Run through this exercise for all the goals and signals you’ve already laid out.</p>

<p>Step 6. Tell me why</p>

<p>The <strong>Why we chose that target</strong>area serves to help you circle back to your North Star. For each measure, note why this is the right direction and then take a step back to make sure that this reason matches up with your team’s overarching
    goal. If you get through the entire exercise and can answer yes to this question, you’re golden!</p>

<p>
    <br>
</p>
`