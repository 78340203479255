<nz-layout class="layout">
  <nz-header *ngIf="canShowHeader"
    nzTheme="light"
    class="header">
    <div class="logo">Ok.wiki<sub>&nbsp;v4</sub></div>
    <ul nz-menu nzMode="horizontal" nzTheme="light">
      <li nz-menu-item nzMatchRouter routerLink="orgs">
        <span class="span-link">My Projects</span>
      </li>
      <li nz-menu-item
        *ngIf="state.featureCheck('f.explore')"
        nzMatchRouter
        routerLink="explore">
        <span class="span-link">Explore</span>
      </li>
      <li nz-menu-item
        *ngIf="state.featureCheck('f.feed')"
        nzMatchRouter
        routerLink="feed">
        <span class="span-link">Feed</span>
      </li>
      <li nz-menu-item
        *ngIf="state.featureCheck('f.search')"
        nzMatchRouter
        routerLink="search">
        <span class="span-link">Search</span>
      </li>
    </ul>
    <div class="user">

      <a nz-button
        href="mailto:denis@ok.wiki?subject=App Ok Wiki - Feedback&body=Hi Team,"
        nzType="default">
        Feedback
      </a>

      <button nzType="link" nz-dropdown nzTrigger="click" [nzDropdownMenu]="userMenu" nz-button>
        <span class="user__name">{{ state.nickname }}</span>
      </button>
      <nz-dropdown-menu #userMenu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="modalProfileOpen()">Profile</li>
          <li nz-menu-item (click)="modalNewPasswordOpen()">Change password</li>
          <li nz-menu-item (click)="state.signOut()">Sign out</li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </nz-header>
  <nz-layout>
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>

<nz-modal [(nzVisible)]="isModalProfileVisible" nzTitle="Profile" [nzFooter]="null" (nzOnCancel)="modalProfileCancel()">
  <form nz-form [formGroup]="profileForm">
    <nz-form-item>
      <nz-form-label nzSpan="6" nzRequired nzFor="email">Email</nz-form-label>
      <nz-form-control nzSpan="18" nzErrorTip="Email is incorrect or invalid">
        <input type="email" nz-input id="email" formControlName="email" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzSpan="6" nzRequired nzFor="nickname">Nickname</nz-form-label>
      <nz-form-control nzSpan="18" nzErrorTip="Your nickname is missing or larger than 40 characters">
        <input type="text" nz-input id="nickname" formControlName="nickname" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzSpan="6" nzFor="firstName">First Name</nz-form-label>
      <nz-form-control nzSpan="18">
        <input type="text" nz-input id="firstName" formControlName="firstName" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzSpan="6" nzFor="lastName">Last Name</nz-form-label>
      <nz-form-control nzSpan="18">
        <input type="text" nz-input id="lastName" formControlName="lastName" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzSpan="6" nzFor="website">Website</nz-form-label>
      <nz-form-control nzSpan="18">
        <input type="text" nz-input id="website" formControlName="website" />
      </nz-form-control>
    </nz-form-item>
  
    <nz-form-item>
      <nz-form-label nzSpan="6" nzFor="company">Company</nz-form-label>
      <nz-form-control nzSpan="18">
        <input type="text" nz-input id="company" formControlName="company" />
      </nz-form-control>
    </nz-form-item>
  
    <nz-form-item>
      <nz-form-control class="text-right">
        <button (click)="modalProfileCancel()" nz-button>Cancel</button>&nbsp;
        <button (click)="updateProfile()" [nzLoading]="isSavingProfile" nz-button
          nzType="primary" [disabled]="!isProfileChanged">Save</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>

<nz-modal [(nzVisible)]="isModalNewPasswordVisible" nzTitle="Set the new password" [nzFooter]="null" (nzOnCancel)="modalNewPasswordCancel()">
  <form nz-form [formGroup]="newPasswordForm">
    <nz-form-item>
      <nz-form-label nzSpan="24" nzRequired nzFor="newPassword">New password</nz-form-label>
      <nz-form-control nzSpan="24" nzErrorTip="Please enter your new password!">
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input formControlName="newPassword" placeholder="Password" maxlength="40" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item>
      <nz-form-label nzSpan="24" nzRequired nzFor="newPasswordConfirm">New password confirm</nz-form-label>
      <nz-form-control nzSpan="24" nzErrorTip="Passwords do not match!">
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input formControlName="newPasswordConfirm" placeholder="Password confirm" maxlength="40" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control class="text-right">
        <button (click)="modalNewPasswordCancel()" nz-button>Cancel</button>&nbsp;
        <button (click)="setNewPassword()" [nzLoading]="isSavingProfile" nz-button nzType="primary"
          [disabled]="!newPasswordForm.valid">Save</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>