// core
import { Injectable } from '@angular/core';

// services
import { LocalStorageService } from 'angular-2-local-storage';
import { LocalUser, User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable()
export class MyLocalStorageService {

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  public getUser(): User {
    const user: User = this.localStorageService.get('user');
    return user;
  }

  public setUser(user: LocalUser): void {
    this.localStorageService.set('user', user);
  }

  public getCommentsPosition(): 'bottom' | 'side' | 'float' {
    return this.localStorageService.get('commentsPosition');
  }

  public setCommentsPosition(type: 'bottom' | 'side' | 'float'): void {
    this.localStorageService.set('commentsPosition', type);
  }

  public getAuthToken(): string {
    return this.localStorageService.get('token');
  }

  public setAuthToken(token: string): void {
    this.localStorageService.set('token', token);
  }

  public getLocalPage(pageId: string): string {
    console.log('getLocalPage', pageId);
    return this.localStorageService.get(pageId);
  }

  public setLocalPage(pageId: string, content: string): void {
    console.log('setLocalPage', pageId);
    this.localStorageService.set(pageId, content);
  }

  public removeLocalPage(pageId: string): void {
    console.log('removeLocalPage', pageId);
    this.localStorageService.remove(pageId);
  }

  public clearStorage(): void {
    this.localStorageService.clearAll();
  }
}
