export const typographyContent = `<h1>Typography</h1>

<p>Normal text: In the process of internal desktop applications development, many different design specs and implementations would be involved, which might cause designers and developers difficulties and duplication and reduce the efficiency of development.</p>

<p>After massive project practice and summaries, Ant Design, a design language for background applications, is refined by Ant UED Team, which aims to uniform the user interface specs for internal background projects, lower the unnecessary cost of design differences and implementation and liberate the resources of design and front-end development .</p>

<p><strong>Bold text</strong></p>

<p><em>Italic text</em></p>

<p><u>Underline text</u></p>

<p><span style="background-color: transparent;"><s>Strikethrow</s></span><s>&nbsp;text</s></p>

<p>Subscript <sub>text</sub></p>

<p>Subscript <sup>text</sup></p>

<p><span class="fr-class-code">Code Style of the text: In the process of internal desktop applications development, many different design specs and implementations would be involved.</span></p>

<pre>Code text: In the process of internal desktop applications development, many different design specs and implementations would be involved.</pre>

<p><span class="fr-class-highlighted">Highlighted text</span></p>

<p><span class="fr-class-transparency">Transparent text</span></p>

<p>Mention: <a>@DioNNiS</a>&nbsp;</p>

<p>Some text - <a href="https://ok.wiki" rel="noopener noreferrer" target="_blank">Link text</a> - Some text</p>

<h1>h1. Title text H1</h1>

<h2>h2. Title text H2</h2>

<h3>h3. Title text H3</h3>

<h4>h4. Title text H4</h4>

<p style="text-align: right;">Align right</p>

<ol>
	<li style="text-align: justify;">Ordered List 1

		<ol>
			<li style="text-align: justify;">Ordered List 1-1

				<ol>
					<li style="text-align: justify;">Ordered List 1-1-1</li>
				</ol>
			</li>
			<li style="text-align: justify;">Ordered List 1-2</li>
			<li style="text-align: justify;">Ordered List 1-3</li>
		</ol>
	</li>
	<li style="text-align: justify;">Ordered List 2</li>
	<li style="text-align: justify;">Ordered List 3</li>
	<li style="text-align: justify;">Ordered List 4</li>
</ol>

<p>
	<br>
</p>

<ul>
	<li>Unordered List 1

		<ul>
			<li style="text-align: justify;">UnorderedList List 1-1

				<ul>
					<li style="text-align: justify;">Unordered List 1-1-1</li>
				</ul>
			</li>
			<li style="text-align: justify;">Unordered List 1-2</li>
			<li style="text-align: justify;">Unordered List 1-3</li>
		</ul>
	</li>
	<li>Unordered List 2</li>
	<li>Unordered List 3</li>
	<li>Unordered List 4</li>
</ul>

<blockquote><pre>Quote 1: In the process of internal desktop applications development, many different design specs and implementations would be involved.</pre></blockquote>

<blockquote>

	<blockquote><pre>Quote 1-2: In the process of internal desktop applications development, many different design specs and implementations would be involved.</pre></blockquote></blockquote>

<blockquote>

	<blockquote>

		<blockquote>

			<blockquote><pre>Quote 1-4: In the process of internal desktop applications development, many different design specs and implementations would be involved.</pre></blockquote></blockquote></blockquote></blockquote>

<p>
	<br>
</p>

<p>Example of horizontal line:</p>
<hr>

<p>
	<br>
</p>

<p>Emoticons:</p>

<p><span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f600.svg);">&nbsp;</span>&nbsp; <span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f601.svg);">&nbsp;</span>&nbsp; <span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f602.svg);">&nbsp;</span>&nbsp; <span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f603.svg);">&nbsp;</span>&nbsp; <span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f608.svg);">&nbsp;</span>&nbsp; <span class="fr-emoticon fr-deletable fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f60d.svg);">&nbsp;</span>&nbsp;</p>

<p>
	<br>
</p>

<h1>Tables</h1>

<h2>Table with Header</h2>

<table style="width: 100%;">
	<thead>
		<tr>
			<th>Some text</th>
			<th>
				<br>
			</th>
			<th>
				<br>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td style="width: 33.3333%;">Some text</td>
			<td style="width: 33.3333%;">
				<br>
			</td>
			<td style="width: 33.3333%;">
				<br>
			</td>
		</tr>
	</tbody>
</table>

<h2>Table with highlighted Column</h2>

<table style="width: 100%;">
	<tbody>
		<tr>
			<td style="width: 33.3333%;">Some text</td>
			<td style="width: 33.3333%; background-color: rgb(239, 239, 239);">Some text</td>
			<td rowspan="3" style="width: 33.3333%; text-align: center;">Merged cells</td>
		</tr>
		<tr>
			<td style="width: 33.3333%;">
				<br>
			</td>
			<td style="width: 33.3333%; background-color: rgb(239, 239, 239);">
				<br>
			</td>
		</tr>
		<tr>
			<td style="width: 33.3333%;">
				<br>
			</td>
			<td style="width: 33.3333%; background-color: rgb(239, 239, 239);">
				<br>
			</td>
		</tr>
		<tr>
			<td style="width: 33.3333%;">
				<br>
			</td>
			<td style="width: 33.3333%; background-color: rgb(239, 239, 239);">
				<br>
			</td>
			<td style="width: 33.3333%; text-align: center;">
				<br>
			</td>
		</tr>
		<tr>
			<td class="fr-highlighted" style="width: 33.3333%;">Cell Style - Highlited</td>
			<td style="width: 33.3333%; background-color: rgb(239, 239, 239);">
				<br>
			</td>
			<td class="fr-thick" style="width: 33.3333%; text-align: center;">Cell Style - Thick</td>
		</tr>
	</tbody>
</table>

<h1>Video</h1>

<h2>Embed Video</h2>

<p><span class="fr-video fr-deletable fr-rv fr-fvc fr-dvb fr-draggable" contenteditable="false" draggable="true"><iframe width="400" height="200" src="https://www.youtube.com/embed/A48AJ_5nWsc" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe></span></p>

<h2>Just Video</h2>

<p>... not working when providing url: https://b6.games/mediaelement/videos/tutti_small.m4v</p>

<h1>Images</h1>

<p>Image example:<img src="https://b6.games/images/apps/tutti.png" style="width: 300px;" class="fr-fic fr-dib"></p>

<p>
	<br>
</p>

<p>Image with Caption:</p>

<p>
	<br>
</p>
<div class="fr-img-space-wrap"><span class="fr-img-caption fr-fic fr-dib" style="width: 300px;"><span class="fr-img-wrap"><img src="https://b6.games/images/apps/tutti.png"><span class="fr-inner">Image Caption</span></span>&nbsp;</span>

	<p class="fr-img-space-wrap2">&nbsp;</p>
</div>

<p>
	<br>
</p>
`